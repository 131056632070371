import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from '../lang/en';
import es from '../lang/es';
import enMessages from 'lang/en';
import esMessages from 'lang/es';
import RoutesContainer from 'containers/RoutesContainer/RoutesContainer';
import PageNotFound from 'components/PageNotFound/PageNotFound';
import Login from 'containers/Auth/Login';
import Global from 'containers/Auth/Global';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'assets/styles/App.scss';
import Version from '../components/Basic/Version';
import TagManager from 'react-gtm-module';
import { gtag } from '../utilities/common';
import { initStore, persistor } from 'core/store';
import { PersistGate } from 'redux-persist/integration/react';

const tagManagerArgs = {
  gtmId: 'G-M5RP94Z2LN',
};

TagManager.initialize(tagManagerArgs);
const initialLang = process.env.REACT_APP_LANG || 'en';

const messages = {
  en: enMessages,
  es: esMessages,
};

const App = () => {
  const [lang, setLang] = useState(initialLang);
  const message = useMemo(() => messages[lang], [lang]);
  const store = initStore();

  useEffect(() => {
    gtag('js', new Date());
    gtag('config', 'G-M5RP94Z2LN');
  }, []);

  return (
    <IntlProvider locale={lang} messages={message}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <div className="App">
              {process.env.REACT_APP_ENV === 'dev' && (
                <div className="lang_btn" onClick={() => setLang(lang === 'en' ? 'es' : 'en')}>
                  {lang}
                </div>
              )}

              <ToastContainer
                autoClose={3000}
                transition={Slide}
                hideProgressBar
                newestOnTop
                position={toast.POSITION.TOP_CENTER}
              />
              <div className="content">
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/global-callback" component={Global} />
                  <Route path="/version" component={Version} />
                  <PrivateRoute path="/" component={RoutesContainer} />
                  <Route exact path="/404" component={PageNotFound} />
                </Switch>
              </div>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
};

export default hot(App);
