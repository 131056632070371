import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Dehaze,
  Apps,
  LocalMall,
  MyLocation,
  Assignment,
  Accessibility,
  Person,
  Call,
  FilterTiltShift,
  AspectRatio,
  PieChartOutlined,
  Autorenew,
  HelpOutlined,
  SettingsApplications,
  VpnKey,
  Build,
  LocalOffer,
} from '@material-ui/icons';
import logoImg from 'assets/img/logo.svg';
import { ReactComponent as LogoutImg } from 'assets/img/logout.svg';
import { ReactComponent as CouponsImg } from 'assets/img/coupons.svg';
import { ReactComponent as DistributionImg } from 'assets/img/distribution.svg';
import { ReactComponent as BadgeImg } from 'assets/img/badge.svg';
import { linkPredictionAdmin } from 'utilities/common';
import { useLogoutMutation } from 'core/auth/LoginService';
import { setInitState } from 'core/auth/LoginSlice';
import { useAppDispatch } from 'utilities/redux';

const NavBar = ({ admin }) => {
  const history = useHistory();
  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();

  const handleLogOut = async () => {
    try {
      await logout({}).unwrap();
      dispatch(setInitState());
      document.cookie = 'connect.sid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      history.push('/');
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="navbar_layout">
      <div className="logo-wrapper">
        <img src={logoImg} alt="logo" />
        <div className="avatar">
          <Dehaze />
        </div>
      </div>
      <nav>
        <NavLink activeClassName="active" to="/dashboard/overview">
          <div className="navItem">
            <Apps />
            <FormattedMessage id="Dashboard" />
          </div>
        </NavLink>
        {(admin.orderAccess ||
          admin.restaurantAccess ||
          admin.catalogAccess ||
          admin.customerAccess ||
          admin.agentAccess ||
          admin.ambassadorAccess ||
          admin.operatorAccess) && (
          <p>
            <FormattedMessage id="Operation management" />
          </p>
        )}
        {admin.orderAccess && (
          <NavLink activeClassName="active" to="/dashboard/orders">
            <div className="navItem">
              <LocalMall />
              <FormattedMessage id="Orders" />
            </div>
          </NavLink>
        )}
        {admin.restaurantAccess && (
          <NavLink activeClassName="active" to="/dashboard/restaurants">
            <div className="navItem">
              <MyLocation />
              <FormattedMessage id="Restaurants" />
            </div>
          </NavLink>
        )}
        {admin.catalogAccess && (
          <NavLink activeClassName="active" to="/dashboard/catalogue">
            <div className="navItem">
              <Assignment />
              <FormattedMessage id="Catalogue" />
            </div>
          </NavLink>
        )}
        {admin.customerAccess && (
          <NavLink activeClassName="active" to="/dashboard/users">
            <div className="navItem">
              <Person />
              <FormattedMessage id="Users" />
            </div>
          </NavLink>
        )}
        {admin.agentAccess && (
          <NavLink activeClassName="active" to="/dashboard/ots">
            <div className="navItem">
              <Call />
              <FormattedMessage id="OTS agents" />
            </div>
          </NavLink>
        )}
        {admin.ambassadorAccess && (
          <NavLink activeClassName="active" to="/dashboard/ambassadors">
            <div className="navItem">
              <Accessibility />
              <FormattedMessage id="Ambassadors" />
            </div>
          </NavLink>
        )}
        {admin.operatorAccess && (
          <NavLink activeClassName="active" to="/dashboard/operators">
            <div className="navItem">
              <VpnKey />
              <FormattedMessage id="Operators" />
            </div>
          </NavLink>
        )}
        {admin.ambassadorAccess && (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_COUNTRY === 'gt') && (
          <NavLink activeClassName="active" to="/dashboard/distribution">
            <div className="navItem">
              <DistributionImg />
              <FormattedMessage id="Distribution" />
            </div>
          </NavLink>
        )}
        {(admin.promotionAccess ||
          admin.couponAccess ||
          admin.notificationAccess ||
          admin.analyticsAccess ||
          admin.dealAccess) && (
          <p>
            <FormattedMessage id="Marketing & Strategy" />
          </p>
        )}
        {admin.dealAccess && (
          <NavLink activeClassName="active" to="/dashboard/deals">
            <div className="navItem">
              <LocalOffer />
              <FormattedMessage id="Deals" />
            </div>
          </NavLink>
        )}
        {admin.promotionAccess && (
          <NavLink activeClassName="active" to="/dashboard/promotions">
            <div className="navItem">
              <AspectRatio />
              <FormattedMessage id="Promotions" />
            </div>
          </NavLink>
        )}
        {admin.couponAccess && (
          <NavLink activeClassName="active" to="/dashboard/coupons">
            <div className="navItem">
              <CouponsImg />
              <FormattedMessage id="Coupons" />
            </div>
          </NavLink>
        )}
        {admin.notificationAccess && (
          <NavLink activeClassName="active" to="/dashboard/notificationes">
            <div className="navItem">
              <FilterTiltShift />
              <FormattedMessage id="Notificaciones" />
            </div>
          </NavLink>
        )}
        <NavLink activeClassName="active" to="/dashboard/loyalty">
          <div className="navItem">
            <BadgeImg />
            <FormattedMessage id="Loyalty program" />
          </div>
        </NavLink>
        {admin.analyticsAccess && (
          <NavLink activeClassName="active" to="/dashboard/analytical">
            <div className="navItem">
              <PieChartOutlined />
              <FormattedMessage id="Analytical" />
            </div>
          </NavLink>
        )}
        <p>
          <FormattedMessage id="Integration and support" />
        </p>
        <NavLink activeClassName="active" to="/dashboard/status">
          <div className="navItem">
            <Autorenew />
            <FormattedMessage id="Service status" />
          </div>
        </NavLink>
        <a href="mailto:support@smart-canvas.com">
          <div className="navItem">
            <HelpOutlined />
            <FormattedMessage id="Support & Help" />
          </div>
        </a>
        <NavLink activeClassName="active" to="/dashboard/sar">
          <div className="navItem">
            <SettingsApplications />
            <FormattedMessage id="SAR Configuration" />
          </div>
        </NavLink>
        {admin.settingAccess && (
          <p>
            <FormattedMessage id="Configurations" />
          </p>
        )}
        {admin.settingAccess && (
          <NavLink activeClassName="active" to="/dashboard/banner">
            <div className="navItem">
              <Build />
              <FormattedMessage id="Customer mobile app" />
            </div>
          </NavLink>
        )}
      </nav>
      <div className="sign-off" onClick={handleLogOut}>
        <LogoutImg />
        <FormattedMessage id="Log out" />
      </div>
    </div>
  );
};

NavBar.defaultProps = {
  admin: {},
};

// const mapStateToProps = ({ auth }) => ({
//   // admin: auth.admin,
// });

// const mapDispatchToProps = dispatch => {
//   const { logOut } = authActionCreators;

//   return bindActionCreators(
//     {
//       logOut,
//     },
//     dispatch,
//   );
// };

export default NavBar;
