import { api } from '..';
import { AdminData, FormLogin } from './types';

export const loginApi = api.enhanceEndpoints({ addTagTypes: ['Login'] }).injectEndpoints({
  endpoints: build => ({
    login: build.mutation<AdminData, FormLogin>({
      query: body => ({
        url: `v0/auth/login/administrator`,
        method: 'POST',
        body,
      }),
    }),
    logout: build.mutation<void, any>({
      query: body => ({
        url: `v0/auth/logout`,
        method: 'PUT',
        params: {
          channel: 'ADMIN',
        },
      }),
    }),
    getProfile: build.query<AdminData, any>({
      query: body => ({
        url: `v0/administrators/self`,
        method: 'GET',
      }),
    }),
    checkAdminEmail: build.mutation<void, { email: string }>({
      query: body => ({
        url: `v1/admins/identify`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useLazyGetProfileQuery, useCheckAdminEmailMutation } = loginApi;
