/*
    Don't remove comments, because MCD team asked  hide the english tab, but not remove it
*/

import React, { useCallback, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Tabs } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeleteOutline, AddPhotoAlternate } from '@material-ui/icons';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import toast from 'components/Basic/Toast';

type PromoImageType = {
  imageGT: any;
  imageEN: any;
  isValid: boolean;
  setImageGT: (value: any) => void;
  setImageEN: (value: any) => void;
};

const PromoImage: React.FC<PromoImageType> = ({ setImageGT, imageGT, setImageEN, imageEN, isValid }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [activeTab, setActiveTab] = useState('Spanish');
  const intl = useIntl();

  const onDrop = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0];
    if (!file) {
      toast.error({
        title: intl.formatMessage({
          id: 'Incorrect file',
        }),
      });
      return;
    }

    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      toast.error({
        title: intl.formatMessage({
          id:
            activeTab === 'Spanish'
              ? 'Se ha subido un formato de archivo incorrecto. Por favor, suba un archivo en formato JPG o PNG.'
              : 'Incorrect file format uploaded. Please upload a JPG or PNG file.',
        }),
      });
      return;
    }

    const reader = new FileReader();

    reader.onload = (event: any) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        if (acceptedFiles[0].size > 1024 * 1024) {
          return toast.error({ title: intl.formatMessage({ id: 'Image size should be less than 1MB' }) });
        }

        setImageEN({
          type: file.type.split('/')[1],
          language: 'english',
          base64: event.target.result.split(',')[1],
        });
        setImageGT({
          type: file.type.split('/')[1],
          language: 'spanish',
          base64: event.target.result.split(',')[1],
        });
      };
    };

    reader.readAsDataURL(file);
  }, []);

  // const onDropGT = useCallback((acceptedFiles: any) => {
  //   const file = acceptedFiles[0];

  //   if (!file) return;

  //   if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
  //     toast.error({
  //       title: intl.formatMessage({
  //         id: activeTab === 'Spanish' ? 'JPG o PNG' : 'JPG or PNG image',
  //       }),
  //     });
  //     return;
  //   }

  //   const reader = new FileReader();

  //   reader.onload = (event: any) => {
  //     const img = new Image();
  //     img.src = event.target.result;
  //     img.onload = () => {
  //       if (acceptedFiles[0].size > 1024 * 1024) {
  //         return toast.error({
  //           title: intl.formatMessage({
  //             id: 'Image size should be less than 1MB',
  //           }),
  //         });
  //       }
  //       setImageGT({
  //         type: file.type.split('/')[1],
  //         language: 'spanish',
  //         base64: event.target.result.split(',')[1],
  //       });
  //     };
  //   };

  //   reader.readAsDataURL(file);
  // }, []);

  // const activeDropHandler = activeTab === 'English' ? onDrop : onDropGT;
  const activeDropHandler = activeTab === 'English' ? onDrop : onDrop;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: activeDropHandler,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    // maxSize: 1200 * 1200,
  });

  const clearImage = useCallback(
    (language: 'EN' | 'GT') => (e: any) => {
      e.stopPropagation();

      const languageImages = {
        EN: () => setImageEN(null),
        GT: () => setImageGT(null),
      };
      languageImages[language]();
    },
    [],
  );

  const onUploadClick = () => {
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const checkField = (value: any) => {
    if (isValid) return;
    if (value) {
      const valid = value?.base64?.length > 0 || value?.image?.length > 0;
      return !valid;
    } else {
      return true;
    }
  };

  const generateTabs = (): Array<any> => {
    let items = [];
    // const enTab =
    //   !isValid && !imageEN ? (
    //     <>
    //       <p style={{ marginBottom: 4 }}>
    //         <FormattedMessage id="Lang on tabs en" />
    //         <span className="dot" />
    //       </p>
    //     </>
    //   ) : (
    //     <>
    //       <p style={{ marginBottom: 4 }}>
    //         <FormattedMessage id="Lang on tabs en" />
    //       </p>
    //     </>
    //   );
    const gtTab =
      !isValid && !imageGT ? (
        <>
          <p style={{ marginBottom: 4 }}>
            <FormattedMessage id="Lang on tabs es" />
            <span className="dot" />
          </p>
        </>
      ) : (
        <>
          <p style={{ marginBottom: 4 }}>
            <FormattedMessage id="Lang on tabs es" />
          </p>
        </>
      );
    items[0] = gtTab;
    // items[0] = enTab;
    return items;
  };

  const callbackTabClicked = (key: any) => {
    key === '0' ? setActiveTab('English') : setActiveTab('Spanish');
  };

  return (
    <>
      <div id="campaign_form_wrapper">
        <div>
          <Tabs
            defaultActiveKey="0"
            tabPosition={'top'}
            onTabClick={callbackTabClicked}
            className="image_tabs"
            activeKey={`${activeTab === 'Spanish' ? 0 : 1}`}
            items={generateTabs().map((item, i) => {
              const id = String(i);
              return {
                label: item,
                key: id,
              };
            })}
          />
        </div>
        {/* {activeTab === 'English' && (
          <div className="imageContainer">
            <div className="basic_info_form">
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} ref={inputRef} />
                {imageEN ? (
                  <>
                    <img
                      src={
                        imageEN?.image?.slice(0, 4) === 'http'
                          ? imageEN?.image
                          : `data:image/${imageEN.type};base64,${imageEN.base64}`
                      }
                      alt="Uploaded preview"
                    />
                    <div className="delete-btn">
                      <Button onClick={clearImage('EN')} className="delete-button">
                        <DeleteOutline />
                      </Button>
                    </div>
                  </>
                ) : (
                  <AddPhotoAlternate className="add-photo-icon" />
                )}
              </div>
            </div>
            <div className="container_valid">
              <p className="image_valid" id="campaign_form_switcher_label">
                <FormattedMessage id="Max weight: 1mb" />
              </p>
              <p className="image_valid" id="campaign_form_switcher_label">
                <FormattedMessage id="Recommended size: 380*292 px" />
              </p>
              <p className="image_valid" id="campaign_form_switcher_label">
                <FormattedMessage id="JPG or PNG image" />
              </p>
              <div className="upload-btn">
                <Button onClick={onUploadClick}>
                  <FileUploadOutlinedIcon />
                  <span>
                    <FormattedMessage id="Upload" />
                  </span>
                </Button>
              </div>
              {checkField(imageEN) && (
                <span className="error_msg">
                  <FormattedMessage id="Image is required" />
                </span>
              )}
            </div>
          </div>
        )} */}
        {activeTab === 'Spanish' && (
          <div className="imageContainer">
            <div className="basic_info_form">
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} ref={inputRef} />
                {imageGT ? (
                  <>
                    <img
                      src={
                        imageGT?.image?.slice(0, 4) === 'http'
                          ? imageGT?.image
                          : `data:image/${imageGT.type};base64,${imageGT.base64}`
                      }
                      alt="Uploaded preview"
                    />
                    <div className="delete-btn">
                      <Button onClick={clearImage('GT')} className="delete-button">
                        <DeleteOutline />
                      </Button>
                    </div>
                  </>
                ) : (
                  <AddPhotoAlternate className="add-photo-icon" />
                )}
              </div>
            </div>
            <div className="container_valid">
              <p className="image_valid" id="campaign_form_switcher_label">
                <FormattedMessage id="Max weight: 1mb" />
              </p>
              <p className="image_valid" id="campaign_form_switcher_label">
                <FormattedMessage id="Recommended size: 380*292 px" />
              </p>
              <p className="image_valid" id="campaign_form_switcher_label">
                <FormattedMessage id="JPG or PNG image" />
              </p>
              <div className="upload-btn">
                <Button onClick={onUploadClick}>
                  <FileUploadOutlinedIcon />
                  <span>
                    <FormattedMessage id="Upload" />
                  </span>
                </Button>
              </div>
              {checkField(imageGT) && (
                <span className="error_msg">
                  <FormattedMessage id="Image is required" />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PromoImage;
