import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Select } from 'antd';
import { ArrowDropDown } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import { useUpdateAmbassadorMutation } from 'core/ambassador/AmbassadorService';
import { validationErrorCodes } from '../../../../utilities/constants';

const { Option } = Select;

const States = [
  {
    value: 'AV',
    label: 'Disponible',
    color: '#7ED321',
  },
  {
    value: 'PA',
    label: 'Preasignado',
    color: '#E8A159',
  },
  {
    value: 'NA',
    label: 'No disponible',
    color: '#BCBCBC',
  },
  {
    value: 'OR',
    label: 'En ruta',
    color: '#50E2C2',
  },
  {
    value: 'FP',
    label: 'Solucionar un problema',
    color: '#FF2527',
  },
  {
    value: 'ORT',
    label: 'En reposo',
    color: '#687992',
  },
  {
    value: 'OT',
    label: 'En otra tarea',
    color: '#5A9D8E',
  },
  {
    value: 'PV',
    label: 'Problemas con el vehículo',
    color: '#FFC200',
  },
];

function AmbassadorAnalysisTable({ data, intl }) {
  const [updateAmbassador] = useUpdateAmbassadorMutation();

  const handleUpdateAmbassadorStatus = async (item, v) => {
    try {
      await updateAmbassador({
        id: item.id,
        updateInfo: { detailedState: v },
      }).unwrap();
      toast.success({
        title: intl.formatMessage({
          id: 'Ambassador status is updated successfully!',
        }),
      });
    } catch (e) {
      if (validationErrorCodes.includes(e.data.code)) {
        toast.error({
          title: intl.formatMessage({ id: e.data.details[0].message }),
        });
      } else {
        toast.error({
          title: intl.formatMessage({
            id: 'Updating ambassador status is failure!',
          }),
        });
      }
    }
  };

  return (
    <div className="ambassador_analysis_table_layout">
      <div className="table_header">
        <div className="code">
          <FormattedMessage id="Ambassador Code" />
        </div>
        <div className="name">
          <FormattedMessage id="Ambassador's name" />
        </div>
        <div className="state">
          <FormattedMessage id="Actual state" />
        </div>
        <div className="delivery_average">
          <FormattedMessage id="Delivery average" />
        </div>
        <div className="delivered_orders">
          <FormattedMessage id="Orders delivered today" />
        </div>
      </div>
      <div className="table_content">
        {data.map((item, index) => (
          <div className="table_item" key={index}>
            <div className="code">
              <p className="title">{item.eid}</p>
            </div>
            <div className="name">
              <p className="title">{`${item.firstName || ''} ${item.lastName || ''}`}</p>
            </div>
            <div className="state">
              {item.detailedState !== 'NA' && (
                <Select
                  suffixIcon={<ArrowDropDown />}
                  defaultValue={item.detailedState}
                  onChange={v => handleUpdateAmbassadorStatus(item, v)}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  {States.map(s => (
                    <Option className="flex align-center" value={s.value} key={s.value}>
                      <div
                        className="status-color"
                        style={{
                          width: 7,
                          height: 7,
                          minWidth: 7,
                          minHeight: 7,
                          borderRadius: '50%',
                          backgroundColor: s.color,
                          marginRight: 17,
                        }}
                      />{' '}
                      {s.label}
                    </Option>
                  ))}
                </Select>
              )}
              {item.detailedState === 'NA' && <p>No disponible en app</p>}
            </div>
            <div className="delivery_average">
              <p className="title">{item.deliveryMinutes1hr || 0} minutos</p>
            </div>
            <div className="delivered_orders">
              <p className="title">{item.orderCount1hr || 0}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

AmbassadorAnalysisTable.defaultProps = {
  data: [],
};

export default compose(injectIntl)(AmbassadorAnalysisTable);
