import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Tooltip, Button, Pagination } from 'antd';
import { Apps, Edit, RemoveRedEye, Delete } from '@material-ui/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UserViewModal from 'components/Dashboard/Users/UserViewModal';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import toast from 'components/Basic/Toast';
import { useDeleteCustomerMutation } from 'core/customer/CustomerService';

function UserTable({ intl, userData, total, onChangePage }) {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [deleteCustomer] = useDeleteCustomerMutation();
  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };

  const handleDeleteUser = async () => {
    if (userInfo.id) {
      try {
        await deleteCustomer(userInfo.id).unwrap();
        onChangePage((current - 1) * pageSize, pageSize);
        toast.success({ title: intl.formatMessage({ id: 'User is deleted successfully!' }) });
      } catch (error) {
        error.data.code === 13229
          ? toast.error({ title: intl.formatMessage({ id: 'UnfinishedOrder' }) })
          : toast.error({ title: intl.formatMessage({ id: 'Deleting user is failure!' }) });
      } finally {
        setIsRemoveModal(false);
      }
    } else {
      toast.error({ title: intl.formatMessage({ id: 'Deleting user is failure!' }) });
    }
  };

  return (
    <div className="user_table_layout">
      <div className="table_header">
        <div className="user_avatar">
          <Apps />
        </div>
        <div className="userid">
          <FormattedMessage id="User ID" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="email">
          <FormattedMessage id="Email" />
        </div>
        <div className="phone">
          <FormattedMessage id="Phone" />
        </div>
        <div className="last_order">
          <FormattedMessage id="Last Order" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {userData &&
          userData.map((item, index) => (
            <div className={`table_item type_state_${item.state === 'A'}_${item.isDeleted}`} key={index}>
              <div className="user_avatar">
                <div className="photo_wrapper" />
              </div>
              <div className="userid">
                <p>{`ID ${item.id}`}</p>
                {item.isDeleted && <span className="deleted">{intl.formatMessage({ id: 'Deleted' })}</span>}
              </div>
              <div className="name">
                <p>{`${item.firstName} ${item.lastName}`}</p>
              </div>
              <div className="email">
                <p>{item.email}</p>
              </div>
              <div className="phone">
                <p>{item.phone}</p>
                {item.phoneVerified && <CheckCircleIcon />}
              </div>
              <div className="last_order">
                <p>
                  {item.lastOrderedAt
                    ? `${moment(item.lastOrderedAt).tz('America/Guatemala').format('LL')} 
                      @${moment(item.lastOrderedAt).tz('America/Guatemala').format('HH:mm')}`
                    : ''}
                </p>
                <div className="device">{item.lastOrderedBy || ''}</div>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'View user details' })}>
                  <Button
                    className="detail_btn"
                    onClick={() => {
                      setUserInfo(item);
                      setShowDetailModal(true);
                    }}
                  >
                    <RemoveRedEye />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit user' })}>
                  <NavLink to={`/dashboard/users/${item.id}`}>
                    <Button className="edit_btn">
                      <Edit />
                    </Button>
                  </NavLink>
                </Tooltip>
                {/* <Tooltip placement="top" title={intl.formatMessage({ id: 'Delete User' })}>
                  <Button
                    className="edit_btn"
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      setUserInfo(item), setIsRemoveModal(true);
                    }}
                  >
                    <Delete />
                  </Button>
                </Tooltip> */}
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
      <UserViewModal
        isOpenModal={isShowDetailModal}
        userInfo={userInfo}
        onCloseModal={() => setShowDetailModal(false)}
      />
      <DeleteConfirmModal
        isOpenModal={isRemoveModal}
        title={intl.formatMessage({ id: 'Do you want to delete the user?' })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting user?',
        })}
        onCancel={() => setIsRemoveModal(false)}
        onOk={handleDeleteUser}
      />
    </div>
  );
}

UserTable.defaultProps = {
  userData: [],
  total: 0,
};

export default compose(injectIntl)(UserTable);
