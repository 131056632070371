import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, SettingsData } from './types';

const initialState: initState = {
  list: {
    id: 0,
    pwaLandingImage: '',
    mobileLandingImage: '',
    iOsVersion: '',
    androidVersion: '',
    forceUpdate: '',
    facebook: '',
    instagram: '',
    twitter: '',
    coupon: '',
    about: '',
    faq: '',
    terms: '',
    privacy: '',
    supportWhatsapp: '',
    s3ImageUpdatedAt: '',
    weeklyOrderLimit: 0,
    monthlyCardLimit: 0,
    monthlyCardDeletionLimit: 0,
    totalCardLimit: 0,
    pointsExpired: false,
    pointsEarning: false,
    btcEnabled: false,
    opennodeParams: '',
    currentLocationEnabled: false,
    forceCustomerProfileUpdatePeriod: 0,
    featureOtpEnabled: false,
    createdAt: '',
    updatedAt: '',
    pointUnit: 0,
    cyberMonday: false,
    trackOrder: false,
    app: {
      merchantId: '',
      orgId: '',
    },
    promoProductEnabled: false,
    promoMinimumPurchase: 0,
    promoProductId: 0,
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsData(state: initState, action: PayloadAction<{ list: SettingsData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setSettingsData } = settingsSlice.actions;

export default settingsSlice.reducer;
