import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Switch, Tabs } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close, Restaurant } from '@material-ui/icons';
import { ArrowForward, RemoveRedEyeOutlined } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import SetupPart from './Setup';
import RedemptionsPart from './Redemptions';
import ExtendedData from './ExtendedData';
import PromoImage from './PromoImage';
import Details from './Details';
import Dates from './Dates';
import Filters from './Filters';
import TabCategory from './TabCategory';
import Location from './Location';
import Hidden from './Hidden';
import {
  useCreateOffersMutation,
  useUpdateOffersMutation,
  useCreateDraftMutation,
  useUpdateDraftMutation,
} from 'core/offers/OffersService';
import { recursivelyTrimStrings } from 'utilities/trim';
import OffersPreviewModal from './OffersPreviewModal';
import SaveAsDraftModal from 'components/Basic/SaveAsDraftModal';
import CloseModalEditOffer from 'components/Basic/CloseModalEditOffer';
import { genetateDefaultDealState } from './defaultStates';
import { generateDealPayload, generateStateDifferences } from './tools';
import { isDateRangeInSelectedDays, isValidDateRange } from './tools/checkValidDates';
import { checkValidProducts } from './tools/checkValidProducts';
import { useHistory } from 'react-router-dom';
import { tabsStringNames } from '../../../utilities/constants';
import { transformDateToCurrentDate } from 'utilities/transformDateTime';

type OffersViewModalType = {
  isDraftPage: boolean;
  offersInfo: any;
  categories: any;
  isShowModal: boolean;
  current: number;
  pageSize: number;
  onShowModal: (e: boolean) => void;
  draftRequest: () => void;
  offersRequest: (c: number, s: number) => void;
};

const OffersViewModal: React.FC<OffersViewModalType> = ({
  isDraftPage,
  offersInfo,
  isShowModal,
  categories,
  current,
  pageSize,
  onShowModal,
  draftRequest,
  offersRequest,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const [createOffers] = useCreateOffersMutation();
  const [updateOffers] = useUpdateOffersMutation();
  const [createDraft] = useCreateDraftMutation();
  const [updateDraft] = useUpdateDraftMutation();

  const [isLoading, setIsLoading] = useState<boolean>();
  const [isShowPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [isShowSaveAsDraftModal, setShowSaveAsDraftModal] = useState<boolean>(false);
  const [isShowCloseEditModal, setShowCloseEditModal] = useState<boolean>(false);

  const [draftModalTitle, setDraftModalTitle] = useState<string>('Save as draft title');
  const [draftModalDesc, setDraftModalDesc] = useState<string>('Save as draft desc');
  const [modalIsCloasing, setModalIsCloasing] = useState<boolean>(false);

  const offersInfoId = offersInfo?.id;

  const draftURL: string = '/dashboard/deals/offers-draft';
  const offerURL: string = '/dashboard/deals/offers';

  const defaultState = genetateDefaultDealState(offersInfoId, offersInfo);
  const tabCount = 9;
  const [state, setState] = useState({
    ...defaultState,
    restaurantIds: defaultState.restaurantIds || defaultState.locations,
  });

  const tabContent = [
    <SetupPart
      setSegmentsName={value => setState(prev => ({ ...prev, name: value }))}
      segmentsName={state.name}
      setSelectedFilterStatus={value =>
        setState(prev => ({
          ...prev,
          campaign: { ...prev.campaign, id: value },
        }))
      }
      selectedFilterStatus={state.campaign?.id}
      isValid={state.statusValid}
    />,
    <RedemptionsPart
      setMinutes={value =>
        setState(prev => ({
          ...prev,
          redemptionConfiguration: { ...prev.redemptionConfiguration, expiration: value },
        }))
      }
      setOffers={value =>
        setState(prev => ({
          ...prev,
          redemptionConfiguration: { ...prev.redemptionConfiguration, limit: value },
        }))
      }
      setDays={value =>
        setState(prev => ({
          ...prev,
          redemptionConfiguration: { ...prev.redemptionConfiguration, repeatDaysCount: value },
        }))
      }
      setTime={value =>
        setState(prev => ({
          ...prev,
          redemptionConfiguration: { ...prev.redemptionConfiguration, repeatStartTime: value },
        }))
      }
      setLimit={value =>
        setState(prev => ({
          ...prev,
          redemptionConfiguration: { ...prev.redemptionConfiguration, repeatCount: value },
        }))
      }
      setSelectedFilterCode={value =>
        setState(prev => ({
          ...prev,
          redemptionConfiguration: { ...prev.redemptionConfiguration, type: value },
        }))
      }
      setIsLimit={value =>
        setState(prev => ({
          ...prev,
          redemptionConfiguration: { ...prev.redemptionConfiguration, limitType: value },
        }))
      }
      setIsRepeatable={value =>
        setState(prev => ({
          ...prev,
          redemptionConfiguration: { ...prev.redemptionConfiguration, isRepeatable: value },
        }))
      }
      minutes={state.redemptionConfiguration?.expiration}
      offers={state.redemptionConfiguration?.limit}
      days={state.redemptionConfiguration?.repeatDaysCount}
      time={state.redemptionConfiguration?.repeatStartTime}
      limit={state.redemptionConfiguration?.repeatCount}
      selectedFilterCode={state.redemptionConfiguration?.type}
      isLimit={state.redemptionConfiguration?.limitType}
      isRepeatable={state.redemptionConfiguration?.isRepeatable}
      numberOfUsed={state.redemptionConfiguration?.numberOfUsed}
      isValid={state.redemptionsValid}
    />,
    <ExtendedData
      setSelectedTemplates={value => setState(prev => ({ ...prev, selectedTemplates: value }))}
      selectedTemplates={state.selectedTemplates}
      setPromoName={value => setState(prev => ({ ...prev, promoName: value }))}
      promoName={state.promoName}
      gtShortDesc={state.gtShortDesc}
      gtLongDesc={state.gtLongDesc}
      setGtShortDesc={value => setState(prev => ({ ...prev, gtShortDesc: value }))}
      setGtLongDesc={value => setState(prev => ({ ...prev, gtLongDesc: value }))}
      setGtProductOne={value => setState(prev => ({ ...prev, gtProductOne: value }))}
      setGtQuantityOne={value => setState(prev => ({ ...prev, gtQuantityOne: value }))}
      setGtPriceOne={value => setState(prev => ({ ...prev, gtPriceOne: value }))}
      setGtDiscountLimitOne={value => setState(prev => ({ ...prev, gtDiscountLimitOne: value }))}
      gtProductOne={state.gtProductOne}
      gtQuantityOne={state.gtQuantityOne}
      gtPriceOne={state.gtPriceOne}
      gtDiscountLimitOne={state.gtDiscountLimitOne}
      setGtProductTwo={value => setState(prev => ({ ...prev, gtProductTwo: value }))}
      setGtQuantityTwo={value => setState(prev => ({ ...prev, gtQuantityTwo: value }))}
      setGtPriceTwo={value => setState(prev => ({ ...prev, gtPriceTwo: value }))}
      setGtDiscountLimitTwo={value => setState(prev => ({ ...prev, gtDiscountLimitTwo: value }))}
      gtProductTwo={state.gtProductTwo}
      gtQuantityTwo={state.gtQuantityTwo}
      gtPriceTwo={state.gtPriceTwo}
      gtDiscountLimitTwo={state.gtDiscountLimitTwo}
      setGtProductCode={value => setState(prev => ({ ...prev, gtProductCode: value }))}
      setGtDiscountAmount={value => setState(prev => ({ ...prev, gtDiscountAmount: value }))}
      gtProductCode={state.gtProductCode}
      gtDiscountAmount={state.gtDiscountAmount}
      setGtProductThree={value => setState(prev => ({ ...prev, gtProductThree: value }))}
      setGtQuantityThree={value => setState(prev => ({ ...prev, gtQuantityThree: value }))}
      setGtPriceThree={value => setState(prev => ({ ...prev, gtPriceThree: value }))}
      setGtDiscountLimitThree={value => setState(prev => ({ ...prev, gtDiscountLimitThree: value }))}
      gtProductThree={state.gtProductThree}
      gtQuantityThree={state.gtQuantityThree}
      gtPriceThree={state.gtPriceThree}
      gtDiscountLimitThree={state.gtDiscountLimitThree}
      setGtProductFour={value => setState(prev => ({ ...prev, gtProductFour: value }))}
      setGtQuantityFour={value => setState(prev => ({ ...prev, gtQuantityFour: value }))}
      setGtPriceFour={value => setState(prev => ({ ...prev, gtPriceFour: value }))}
      setGtDiscountLimitFour={value => setState(prev => ({ ...prev, gtDiscountLimitFour: value }))}
      gtProductFour={state.gtProductFour}
      gtQuantityFour={state.gtQuantityFour}
      gtPriceFour={state.gtPriceFour}
      gtDiscountLimitFour={state.gtDiscountLimitFour}
      setGtReducedQuantity={value => setState(prev => ({ ...prev, gtReducedQuantity: value }))}
      gtReducedQuantity={state.gtReducedQuantity}
      setPromoNameEn={value => setState(prev => ({ ...prev, promoNameEn: value }))}
      promoNameEn={state.promoNameEn}
      enShortDesc={state.enShortDesc}
      setEnShortDesc={value => setState(prev => ({ ...prev, enShortDesc: value }))}
      enLongDesc={state.enLongDesc}
      setEnLongDesc={value => setState(prev => ({ ...prev, enLongDesc: value }))}
      isValid={state.extendedValid}
    />,
    <PromoImage
      setImageEN={value =>
        setState(prev => ({
          ...prev,
          images: [value, prev.images?.[1], ...(prev.images?.slice(2) || [])],
        }))
      }
      setImageGT={value =>
        setState(prev => ({
          ...prev,
          images: [prev.images?.[0], value, ...(prev.images?.slice(2) || [])],
        }))
      }
      imageGT={state.images?.[1]}
      imageEN={state.images?.[0]}
      isValid={state.imageValid}
    />,
    <Details
      setTitle={value => setState(prev => ({ ...prev, title: value }))}
      title={state.title}
      setDescription={value => setState(prev => ({ ...prev, description: value }))}
      description={state.description}
      setTerms={value => setState(prev => ({ ...prev, terms: value }))}
      terms={state.terms}
      isValid={state.detailsValid}
    />,
    <TabCategory
      isValid={state.tabcatValid}
      selectedTabs={state?.tabs}
      selectedCategory={state.category?.id}
      setSelectedTabs={values => setState(prev => ({ ...prev, tabs: values }))}
      setSelectedCategory={value =>
        setState(prev => ({
          ...prev,
          category: { ...prev.category, id: value },
        }))
      }
    />,
    <Dates
      setIsMonday={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, isMonday: value } }))}
      isMonday={state.schedule?.isMonday}
      setIsTuesday={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, isTuesday: value } }))}
      isTuesday={state.schedule?.isTuesday}
      setIsWednesday={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, isWednesday: value } }))}
      isWednesday={state.schedule?.isWednesday}
      setIsThursday={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, isThursday: value } }))}
      isThursday={state.schedule?.isThursday}
      setIsFriday={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, isFriday: value } }))}
      isFriday={state.schedule?.isFriday}
      setIsSunday={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, isSunday: value } }))}
      isSunday={state.schedule?.isSunday}
      onStartDate={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, startDate: value } }))}
      onStartTime={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, startTime: value } }))}
      onEndDate={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, endDate: value } }))}
      onEndTime={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, endTime: value } }))}
      onDailyStartTime={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, dailyStartTime: value } }))}
      onDailyEndTime={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, dailyEndTime: value } }))}
      startDate={state.schedule.startDate}
      startTime={state.schedule.startTime}
      endDate={state.schedule.endDate}
      endTime={state.schedule.endTime}
      dailyStartTime={state.schedule.dailyStartTime}
      dailyEndTime={state.schedule.dailyEndTime}
      setIsSaturday={value => setState(prev => ({ ...prev, schedule: { ...prev.schedule, isSaturday: value } }))}
      isSaturday={state.schedule?.isSaturday}
      isValid={state.datesValid}
      validationDates={state.validationDates}
    />,
    <Filters
      selectedFilterStatus={state.segment?.id}
      setSelectedFilterStatus={value =>
        setState(prev => ({
          ...prev,
          segment: { ...prev.segment, id: value },
        }))
      }
      lowerList={state.lowerList}
      setLowerList={value => setState(prev => ({ ...prev, lowerList: value }))}
      upperList={state.upperList}
      setUpperList={value => setState(prev => ({ ...prev, upperList: value }))}
      isValid={state.segmentValid}
    />,
    <Location
      isLocation={state.locationType}
      setIsLocation={value => setState(prev => ({ ...prev, locationType: value }))}
      setSelectedRestaurant={value => setState(prev => ({ ...prev, restaurantIds: value }))}
      selectedRestaurant={state.restaurantIds}
      isValid={state.locationValid}
    />,
    <Hidden
      setIsShortUrl={value => setState(prev => ({ ...prev, isShortUrl: value }))}
      isShortUrl={state.isShortUrl}
      setIsHidden={value => setState(prev => ({ ...prev, isHidden: value }))}
      isHidden={state.isHidden}
      setIsSecret={value => setState(prev => ({ ...prev, isSecret: value }))}
      isSecret={state.isSecret}
      offerId={offersInfo?.id}
      shortUrl={offersInfo?.shortUrl}
      longUrl={offersInfo?.longUrl}
      qrCode={offersInfo?.qrCode}
      hiddenCode={offersInfo?.hiddenCode}
      isDraftPage={isDraftPage}
    />,
  ];

  const RedDot = () => <span className="red_dot" />;

  const tabNames = [
    <>
      {intl.formatMessage({ id: 'Setup' })}
      {!state.statusValid && <RedDot />}
    </>,
    <>
      {intl.formatMessage({ id: 'Redemptions' })}
      {!state.redemptionsValid && <RedDot />}
    </>,
    <>
      {intl.formatMessage({ id: 'ExtendedData' })}
      {!state.extendedValid && <RedDot />}
    </>,
    <>
      {intl.formatMessage({ id: 'PromotionalImage' })}
      {!state.imageValid && <RedDot />}
    </>,
    <>
      {intl.formatMessage({ id: 'Details' })}
      {!state.detailsValid && <RedDot />}
    </>,
    <>
      {intl.formatMessage({ id: 'TabCategory' })}
      {!state.tabcatValid && <RedDot />}
    </>,
    <>
      {intl.formatMessage({ id: 'Dates' })}
      {!state.datesValid && <RedDot />}
    </>,
    <>
      {intl.formatMessage({ id: 'Filters' })}
      {!state.segmentValid && <RedDot />}
    </>,
    <>
      {intl.formatMessage({ id: 'Location' })}
      {!state.locationValid && <RedDot />}
    </>,
    intl.formatMessage({ id: 'Hidden' }),
  ];

  const validateFields = (id?: number) => {
    const caseData = id !== undefined && id >= 0 ? id : state.activeTab;

    switch (+caseData) {
      case 0:
        const statusValid = !!(state?.name?.length > 0 && state?.campaign);
        setState(prev => ({ ...prev, statusValid }));
        return statusValid;
      case 1:
        let redemptionsValid =
          state?.redemptionConfiguration?.type &&
          state?.redemptionConfiguration?.expiration > 0 &&
          state?.redemptionConfiguration?.limitType.length > 0;

        if (state?.redemptionConfiguration?.limitType === 'limited') {
          redemptionsValid = redemptionsValid && state.redemptionConfiguration?.limit > 0;
        }
        if (state.redemptionConfiguration.isRepeatable) {
          if (state.redemptionConfiguration?.repeatDaysCount === 0) {
            redemptionsValid = redemptionsValid && state.redemptionConfiguration?.repeatCount > 0;
          } else if (state.redemptionConfiguration?.repeatCount === 0) {
            redemptionsValid = redemptionsValid && state.redemptionConfiguration?.repeatDaysCount > 0;
          } else {
            redemptionsValid =
              redemptionsValid &&
              state.redemptionConfiguration?.repeatDaysCount > 0 &&
              state.redemptionConfiguration?.repeatCount > 0;
          }
        }
        redemptionsValid = !!redemptionsValid;
        setState(prev => ({ ...prev, redemptionsValid }));
        return redemptionsValid;
      case 2:
        const defaultFields = [
          state.promoNameEn,
          state.promoName,
          state.enShortDesc,
          state.gtShortDesc,
          state.enLongDesc,
          state.gtLongDesc,
        ];

        const productOne = [
          state.gtProductOne,
          state.gtQuantityOne,
          Number(state.gtPriceOne) >= 0,
          Number(state.gtDiscountLimitOne) >= 0,
        ];
        const productTwo = [
          state.gtProductTwo,
          state.gtQuantityTwo,
          Number(state.gtPriceTwo) >= 0,
          Number(state.gtDiscountLimitTwo) >= 0,
        ];
        const productThree = [
          state.gtProductThree,
          state.gtQuantityThree,
          Number(state.gtPriceThree) >= 0,
          Number(state.gtDiscountLimitThree) >= 0,
        ];

        const productFour = [
          state.gtProductFour,
          state.gtQuantityFour,
          Number(state.gtPriceFour) >= 0,
          Number(state.gtDiscountLimitFour) >= 0,
        ];

        let validFields = [];

        switch (state?.selectedTemplates) {
          case 'price_deal_two_gt':
            validFields = [...productOne, ...productTwo];
            break;
          case 'price_deal_three_gt':
            validFields = [...productOne, ...productTwo, ...productThree];
            break;
          case 'price_deal_four_gt':
            validFields = [...productOne, ...productTwo, ...productThree, ...productFour];
            break;
          case 'discount_off_product_gt':
            /* 
              discountAmount has default value = 0;             
              state.gtDiscountAmount && Number(state.gtDiscountAmount) > 0,
            */
            validFields = [state.gtProductCode, Number(state.gtPriceOne) >= 0];
            break;
          case 'discount_off_total_order_gt':
            validFields = [state.gtDiscountAmount];
            break;
          case 'buy_one_get_two_gt':
            validFields = [
              state.gtProductCode,
              state.gtReducedQuantity,
              state.gtProductOne,
              Number(state.gtPriceOne) >= 0,
              Number(state.gtDiscountLimitOne) >= 0,
              state.gtProductTwo,
              Number(state.gtPriceTwo) >= 0,
              Number(state.gtDiscountLimitTwo) >= 0,
            ];
            break;
          default:
            validFields = [false];
            break;
        }

        validFields.unshift(...defaultFields);

        const result = checkValidProducts([
          state.gtProductOne,
          state.gtProductTwo,
          state.gtProductThree,
          state.gtProductFour,
          state.gtProductCode,
        ]);

        const isFieldsValid = validFields.every(field => Boolean(field));
        const extendedValid = result && isFieldsValid;

        setState(prev => ({ ...prev, extendedValid }));
        return extendedValid;
      case 3:
        const imageValid = !!(state?.images?.length === 2 && state.images[0] && state.images[1]);
        setState(prev => ({ ...prev, imageValid }));
        return imageValid;
      case 4:
        const detailsValid = !!(state?.title && state?.description && state?.terms);
        setState(prev => ({ ...prev, detailsValid }));
        return detailsValid;
      case 5:
        const tabcatValid = !!(state?.category?.id && state?.tabs?.length);
        setState(prev => ({ ...prev, tabcatValid }));
        return tabcatValid;
      case 6:
        const { schedule } = state;
        const selectedDays = [
          schedule.isMonday,
          schedule.isTuesday,
          schedule.isWednesday,
          schedule.isThursday,
          schedule.isFriday,
          schedule.isSaturday,
          schedule.isSunday,
        ];
        const daysValid =
          schedule.isMonday ||
          schedule.isTuesday ||
          schedule.isWednesday ||
          schedule.isThursday ||
          schedule.isFriday ||
          schedule.isSaturday ||
          schedule.isSunday;

        const timeFieldsValid =
          !!schedule.startDate &&
          !!schedule.startTime &&
          !!schedule.endDate &&
          !!schedule.endTime &&
          !!schedule.dailyStartTime &&
          !!schedule.dailyEndTime;

        const weekDaysCheck = isDateRangeInSelectedDays(schedule.startDate, schedule.endDate, selectedDays);

        const allDateCheck = isValidDateRange(
          schedule.startDate,
          schedule.endDate,
          schedule.startTime,
          schedule.endTime,
          schedule.dailyStartTime,
          schedule.dailyEndTime,
        );
        const periodsValid = typeof weekDaysCheck === 'string' || typeof allDateCheck === 'string' ? false : true;

        const validationDates = allDateCheck;
        const datesValid = !!(daysValid && timeFieldsValid && periodsValid);
        setState(prev => ({ ...prev, validationDates }));
        setState(prev => ({ ...prev, datesValid }));
        return datesValid;
      case 7:
        const segmentValid = !!(state.segment?.id && state.lowerList?.length);
        setState(prev => ({ ...prev, segmentValid }));
        return segmentValid;
      case 8:
        const locationValid =
          state?.locationType === 'everywhere'
            ? !!state?.locationType
            : !!(state?.locationType && state.restaurantIds?.length > 0);

        setState(prev => ({ ...prev, locationValid }));
        return locationValid;
      default:
        return true;
    }
  };

  const handleSaveOfferButtonClick = async () => {
    const isUpdateOperation = offersInfo && offersInfo.id;

    try {
      const validationResults = tabNames.map((_, index) => validateFields(index));

      const commonValidationResult = validationResults.includes(false);

      setIsLoading(true);

      if (!commonValidationResult) {
        const payload = generateDealPayload(state);

        const data = recursivelyTrimStrings(payload);

        if (isUpdateOperation) {
          if (isDraftPage) {
            await createOffers({ offerData: { ...data, draftId: offersInfo.id } }).unwrap();
          } else {
            await updateOffers({
              id: offersInfo.id,
              offerData: {
                ...data,
                schedule: {
                  ...data.schedule,
                  dailyEndTime: transformDateToCurrentDate(data.schedule.dailyEndTime),
                },
              },
            }).unwrap();
          }
        } else {
          await createOffers({ offerData: data }).unwrap();
        }

        if (isDraftPage) {
          draftRequest();
        } else {
          offersRequest(current, pageSize);
        }

        onShowModal(!isShowModal);

        setState(defaultState);

        const messageId = isUpdateOperation ? 'Offer is updated successfully' : 'Offer is created successfully';

        toast.success({ title: intl.formatMessage({ id: messageId }) });
      } else {
        if (!isUpdateOperation || isDraftPage) {
          setModalIsCloasing(false);
          setDraftModalTitle('Save as draft title');
          setDraftModalDesc('Save as draft desc');
          setShowSaveAsDraftModal(true);
        } else {
          const filterArray = tabsStringNames.filter((_, index) => !validationResults[index]);
          const res = filterArray.map(item => intl.formatMessage({ id: item })).join(', ');
          toast.error({
            title: intl.formatMessage(
              { id: 'Updating offer is failure. Please, check the next tabs: {Tabs}' },
              { Tabs: res },
            ),
          });
        }
      }
    } catch {
      const messageId = isUpdateOperation ? 'Updating offer is failure' : 'Creating offer is failure';

      toast.error({ title: intl.formatMessage({ id: messageId }) });
    } finally {
      setIsLoading(false);
      isDraftPage ? history.replace(draftURL) : history.replace(offerURL);
    }
  };

  const onCloseModals = () => {
    setShowSaveAsDraftModal(false);
    onShowModal(false);
    setState(defaultState);
    setShowCloseEditModal(false);
    isDraftPage ? history.replace(draftURL) : history.replace(offerURL);
  };

  const onMutationDraft = async () => {
    const isUpdateOperation = offersInfo && offersInfo.id;

    try {
      setIsLoading(true);

      const payload = generateDealPayload(state);

      if (isUpdateOperation) {
        await updateDraft({ id: offersInfo.id, body: { data: payload } }).unwrap();
      } else {
        await createDraft({ body: { data: payload } }).unwrap();
      }

      if (isDraftPage) {
        draftRequest();
      }

      setShowSaveAsDraftModal(false);

      const messageId = isUpdateOperation ? 'Draft is updated successfully' : 'Draft is created successfully';

      toast.success({ title: intl.formatMessage({ id: messageId }) });

      onCloseModals();
    } catch {
      const messageId = isUpdateOperation ? 'Updating draft is failure' : 'Creating draft is failure';

      toast.error({ title: intl.formatMessage({ id: messageId }) });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (index: number) => {
    tabNames.forEach((_, id) => id < index && validateFields(id));

    setState(prev => ({ ...prev, activeTab: index }));
  };

  const onControllerClick = (action: any) => {
    if (state.activeTab >= 0 && state.activeTab <= tabCount) {
      if (action === 'next') {
        validateFields();
        const newActiveTab = +state.activeTab + 1;
        setState(prev => ({ ...prev, activeTab: newActiveTab <= tabCount ? newActiveTab : tabCount }));
      } else {
        const newActiveTab = +state.activeTab - 1;
        setState(prev => ({ ...prev, activeTab: newActiveTab >= 0 ? newActiveTab : 0 }));
      }
    }
  };

  const onShowPreviewModal = () => {
    onShowModal(!isShowModal);
    setShowPreviewModal(!isShowPreviewModal);
  };

  const disabledPreveiw = () => {
    const { title, description, images, schedule } = state;
    const {
      dailyStartTime,
      dailyEndTime,
      isMonday,
      isTuesday,
      isWednesday,
      isThursday,
      isFriday,
      isSaturday,
      isSunday,
    } = schedule;

    return !(
      title &&
      description &&
      images &&
      dailyStartTime &&
      dailyEndTime &&
      (isMonday || isTuesday || isWednesday || isThursday || isFriday || isSaturday || isSunday)
    );
  };

  const onCloseOfferModal = () => {
    const isUpdateOperation = offersInfo && offersInfo.id;
    const newState = generateDealPayload(state);
    const changes = !isDraftPage ? generateStateDifferences(offersInfo, newState) : false;
    if (!isUpdateOperation || isDraftPage) {
      setModalIsCloasing(true);
      setDraftModalTitle('Close without saving title');
      setDraftModalDesc('Close without saving title');
      setShowSaveAsDraftModal(true);
    } else if (isUpdateOperation && changes) {
      setShowCloseEditModal(true);
    } else {
      onShowModal(false);
      isDraftPage ? history.replace(draftURL) : history.replace(offerURL);
    }
  };

  useEffect(() => {
    if (isDraftPage) {
      tabNames.forEach((_, index) => validateFields(index));
    }
  }, [isDraftPage]);

  const callbackTabClicked = (key: any) => {
    handleTabClick(key);
  };

  return (
    <>
      <ModalDlg
        className="offers_view_modal"
        visible={isShowModal}
        width={430}
        handleCancel={onCloseOfferModal}
        isCenter
      >
        <>
          <div className="modal_header">
            <div className="name_wrapper">
              <h3>
                <FormattedMessage id={offersInfoId ? 'Edit Offer' : 'New offer'} />
              </h3>
              {offersInfoId && (
                <div className="name_wrapper_id">
                  <FormattedMessage id="OfferId" values={{ id: offersInfoId }} />
                </div>
              )}
              <div className="close_modal" onClick={onCloseOfferModal}>
                <Close />
              </div>
            </div>
          </div>
          <div className="new_offers_form_wrapper">
            <Tabs
              defaultActiveKey="0"
              tabPosition={'top'}
              onTabClick={callbackTabClicked}
              className="offers_tab"
              popupClassName="tabs_more_menu"
              activeKey={`${state.activeTab}`}
              items={tabNames.map((item, i) => {
                const id = String(i);
                return {
                  label: item,
                  key: id,
                };
              })}
            />
            <Form className="basic_info_form">
              <div className="tab_content">{tabContent[state.activeTab]}</div>
              <Form.Item>
                <>
                  <div className="btn_arrows">
                    <Button
                      className="arrow_left"
                      onClick={() => onControllerClick('prev')}
                      style={state.activeTab === 0 ? { visibility: 'hidden' } : {}}
                    >
                      <ArrowForward style={{ transform: 'rotate(180deg)' }} />
                      <FormattedMessage id={'Prev'} />
                    </Button>
                    <Button
                      className="arrow_right"
                      onClick={() => onControllerClick('next')}
                      style={state.activeTab === tabCount ? { visibility: 'hidden' } : {}}
                    >
                      <FormattedMessage id={'Next'} />
                      <ArrowForward />
                    </Button>
                  </div>
                  <div className="side_btns_cat">
                    <div className="aditional_detail">
                      <p id="campaign_form_switcher_label">
                        <FormattedMessage id="Active" />
                      </p>
                      <Switch
                        checked={state.isActive}
                        onChange={checked => setState(prev => ({ ...prev, isActive: checked }))}
                        id="campaign_form_switcher"
                      />
                    </div>
                    <div className="btn_wrapper_offer">
                      <Button className="save_sar_btn" disabled={disabledPreveiw()} onClick={onShowPreviewModal}>
                        <FormattedMessage id={'Preview'} />
                        <RemoveRedEyeOutlined />
                      </Button>
                      <Button className="save_sar_btn" disabled={isLoading} onClick={handleSaveOfferButtonClick}>
                        <FormattedMessage id={!offersInfoId ? 'Save' : 'Save changes'} />
                        <ArrowForward />
                      </Button>
                    </div>
                  </div>
                </>
              </Form.Item>
            </Form>
          </div>
          {isLoading && <Loading visible={isLoading} />}
        </>
      </ModalDlg>
      <OffersPreviewModal deal={state} isShowModal={isShowPreviewModal} onShowModal={onShowPreviewModal} />
      <SaveAsDraftModal
        isLoading={isLoading}
        isClosing={modalIsCloasing}
        isOpenModal={isShowSaveAsDraftModal}
        title={intl.formatMessage({ id: draftModalTitle })}
        subtitle={intl.formatMessage({ id: draftModalDesc })}
        onCancel={() => setShowSaveAsDraftModal(false)}
        onClose={onCloseModals}
        onSave={onMutationDraft}
      />
      <CloseModalEditOffer
        isOpenModal={isShowCloseEditModal}
        onCancel={() => setShowCloseEditModal(false)}
        onClose={onCloseModals}
      />
    </>
  );
};

export default OffersViewModal;
