export const getDefaultSize = sizes => {
  return !sizes.length
    ? {}
    : sizes.find(({ defaultValue }) => defaultValue === 'S') || sizes.find(({ size }) => size === 2) || sizes[0];
};

export const getProductInfo = (data, prices, sizes) => {
  let defaultPrice = 0;
  let defaultCode = 0;
  if (data && prices && sizes) {
    defaultCode = data.productCode;
    if (data.type !== '1') {
      const productVariants = sizes.filter(
        ({ classCode, item, state }) => state === 'A' && classCode === data.classCode && item === data.item,
      );
      if (productVariants.length > 0) {
        defaultCode = getDefaultSize(productVariants).productCode;
      }
    }
    if (defaultCode !== 0) {
      defaultPrice = (prices.find(({ product }) => product === defaultCode) || {}).price || 0;
    }
  }
  return { productCode: defaultCode, price: defaultPrice / 100 };
};

const currencies = {
  gt: {
    singular: 'Quetzal',
    plural: 'Quetzales',
    symbol: 'Q',
  },
  sv: {
    singular: 'Dólar',
    plural: 'Dólares',
    symbol: '$',
  },
  hn: {
    singular: 'Lempira',
    plural: 'Lempiras',
    symbol: 'L',
  },
  ni: {
    singular: 'Córdova',
    plural: 'Córdovas',
    symbol: 'C$',
  },
};
const locations = {
  gt: {
    lat: 14.628434,
    lng: -90.522713,
  },
  sv: {
    lat: 13.8029939,
    lng: -88.9053364,
  },
  hn: {
    lat: 14.7503821,
    lng: -86.241341,
  },
  ni: {
    lat: 12.8691653,
    lng: -85.1411896,
  },
};
const codes = {
  us: 1,
  gt: 502,
  sv: 503,
  hn: 504,
  ni: 505,
};
const countryNames = {
  us: 'United State',
  gt: 'Guatemala',
  sv: 'El salvador',
  hn: 'Honduras',
  ni: 'Nicaragua',
};

export const getCurrency = () => {
  return currencies[process.env.REACT_APP_COUNTRY || 'gt'];
};
export const getLocations = () => {
  return locations[process.env.REACT_APP_COUNTRY || 'gt'];
};
export const getPhoneCode = () => {
  return codes[process.env.REACT_APP_COUNTRY || 'gt'];
};
export const getCountry = () => {
  return countryNames[process.env.REACT_APP_COUNTRY || 'gt'];
};

export function linkPredictionAdmin() {
  return `https://admin.${process.env.REACT_APP_FIFA}.mcdonaldsfifaworldcup.com`;
}

export function gtag() {
  window.dataLayer.push(arguments);
}

export const isAbsoluteURL = url => {
  return /^https?:\/\//i.test(url);
};

export const getImageLink = (itemLink, withSlash) => {
  if (isAbsoluteURL(itemLink)) {
    return itemLink;
  } else {
    // TODO: remove after transfer all images from locale to S3
    return `${process.env.REACT_APP_SERVER}${withSlash ? '/' : ''}${itemLink}`;
  }
};
