import { Form, Input } from 'antd';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import DropMenu from 'components/Basic/DropMenu';
import Loading from 'components/Basic/Loading';
import toast from 'components/Basic/Toast';
import RestaurantMap from 'components/Dashboard/Restaurants/RestaurantMap';
import RestaurantSettings from 'components/Dashboard/Restaurants/RestaurantSettings';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import {
  useCreateRestaurantMutation,
  useDeleteRestaurantMutation,
  useLazyGetRestaurantQuery,
  useUpdateRestaurantMutation,
} from 'core/restaurant/RestaurantService';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getLocations } from 'utilities/common';

// import { PanoramaFishEyeOutlined } from '@material-ui/icons';

const States = [
  { key: 'A', value: 'Active' },
  { key: 'I', value: 'Inactive' },
];

const RestaurantNew = ({ match }) => {
  const [state, setState] = useState({
    restaurantInfo: {
      deliveryTime1: 15,
      reserveDays: 0,
      ambassadorCapacity: 1,
      ambassadorFactor: 1,
      lat: getLocations().lat,
      lng: getLocations().lng,
    },
    isRemoveModal: false,
    isLoading: false,
  });

  const { id } = match.params;

  const intl = useIntl();
  const history = useHistory();

  const [getRestaurant] = useLazyGetRestaurantQuery();
  const [createRestaurant] = useCreateRestaurantMutation();
  const [updateRestaurant] = useUpdateRestaurantMutation();
  const [deleteRestaurant] = useDeleteRestaurantMutation();

  useEffect(() => {
    if (id) {
      getRestaurant({ id })
        .unwrap()
        .then(res => {
          setState(prev => ({ ...prev, restaurantInfo: res }));
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  const handleFilterSelect = (type, key) => {
    handleChange('state', States[key].key);
  };

  const handleChange = (field, value) => {
    setState(prev => ({ ...prev, restaurantInfo: { ...state.restaurantInfo, [field]: value } }));
  };

  const handleUpdateRestaurant = async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    if (id) {
      await updateRestaurant({ restaurantInfo: state.restaurantInfo, id: id })
        .unwrap()
        .then(() => {
          setState(prev => ({ ...prev, isLoading: false }));
          toast.success({
            title: intl.formatMessage({
              id: 'Restaurant is updated successfully!',
            }),
          });
        })
        .catch(err => {
          setState(prev => ({ ...prev, isLoading: false }));
          toast.error({
            title: intl.formatMessage({
              id: 'Updating restaurant is failure!',
            }),
          });
        });
    } else {
      await createRestaurant({ rid: state.restaurantInfo.id, ...state.restaurantInfo })
        .unwrap()
        .then(() => {
          setState(prev => ({ ...prev, isLoading: false }));
          toast.success({
            title: intl.formatMessage({
              id: 'Restaurant is created successfully!',
            }),
          });
          history.push('/dashboard/restaurants');
        })
        .catch(err => {
          setState(prev => ({ ...prev, isLoading: false }));
          if (err.data.details && err.data.details[0].message === 'notNull Violation: Restaurant.long cannot be null') {
            toast.error({
              title: intl.formatMessage({
                id: 'RestaurantLocationValidationError',
              }),
            });
          } else {
            toast.error({
              title: intl.formatMessage({
                id: 'Creating restaurant is failure or Restaurant code is already existed!',
              }),
            });
          }
        });
    }
  };

  const handleDeleteRestaurant = () => {
    setState(prev => ({ ...prev, isRemoveModal: true }));
  };

  const handleConfirmDelete = async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    if (id) {
      await deleteRestaurant({ id })
        .unwrap()
        .then(() => {
          setState(prev => ({ ...prev, isLoading: false }));
          toast.success({
            title: intl.formatMessage({
              id: 'Restaurant is deleted successfully!',
            }),
          });
          history.push('/dashboard/restaurants');
        })
        .catch(err => {
          setState(prev => ({ ...prev, isLoading: false }));
          toast.error({
            title: intl.formatMessage({
              id: 'Deleting restaurant is failure!',
            }),
          });
        });
    }
  };

  const handleDragMap = (position, address) => {
    setState(prev => ({
      ...prev,
      restaurantInfo: { ...state.restaurantInfo, lat: position.lat, long: position.lng },
    }));
  };

  return (
    <div className="restaurants_new_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader
        type="restaurant_new"
        onDeleteRestaurant={handleDeleteRestaurant}
        onUpdateRestaurant={handleUpdateRestaurant}
      />
      <div className="main_content">
        <div className="restaurants_info_wrapper">
          <h3>
            <FormattedMessage id="Basic information" />
          </h3>
          <div className="base_info_wrapper">
            <Form className="basic_info_form">
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Name" />
                </p>
                <p className="description">
                  <FormattedMessage id="It will be used for the display of the restaurant name" />
                </p>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'Enter the reference name of the restaurant',
                  })}
                  value={state.restaurantInfo.description || ''}
                  onChange={e => handleChange('description', e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Textual direction" />
                </p>
                <p className="description">
                  <FormattedMessage id="It will be used for internal management" />
                </p>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'Enter the reference name of the restaurant',
                  })}
                  value={state.restaurantInfo.address || ''}
                  onChange={e => handleChange('address', e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Phone" />
                </p>
                <p className="description">
                  <FormattedMessage id="It will be used for internal management" />
                </p>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'Enter the reference name of the restaurant',
                  })}
                />
              </Form.Item>
              <Form.Item className="actual_state">
                <p className="title">
                  <FormattedMessage id="Actual state" />
                </p>
                <p className="description">
                  <FormattedMessage id="Check the current state of the restaurant" />
                </p>
                <div>
                  <DropMenu
                    className="state_filter"
                    items={States}
                    onMenu={key => handleFilterSelect('state_filter', key)}
                    placeholder={intl.formatMessage({
                      id: 'All the states',
                    })}
                    defaultValue={state.restaurantInfo.state || ''}
                  />
                </div>
              </Form.Item>

              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Inactivity message" />
                </p>
                <p className="description">
                  <FormattedMessage id="It will be used to notify users of the reason for the inactivity" />
                </p>
                {state.restaurantInfo.state === 'I' && (
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'Enter a short description, eg. Due to heavy rains and floods, we will return tomorrow',
                    })}
                    value={state.restaurantInfo.inactiveMessage || ''}
                    onChange={e => handleChange('inactiveMessage', e.target.value)}
                  />
                )}
              </Form.Item>
            </Form>
          </div>
          <RestaurantMap
            restaurantInfo={state.restaurantInfo}
            onChange={(field, value) => handleChange(field, value)}
            onDragMap={handleDragMap}
          />
          <RestaurantSettings
            restaurantInfo={state.restaurantInfo}
            onChange={(field, value) => handleChange(field, value)}
          />
        </div>
      </div>
      <DeleteConfirmModal
        isOpenModal={state.isRemoveModal}
        title={intl.formatMessage({
          id: 'Do you really want to remove this restaurant?',
        })}
        subtitle={intl.formatMessage({
          id: 'Restaurant Delete Description',
        })}
        onCancel={() => setState({ isRemoveModal: false })}
        onOk={handleConfirmDelete}
      />
    </div>
  );
};

export default RestaurantNew;
