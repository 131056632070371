import { Apps, Edit, RemoveRedEye } from '@material-ui/icons';
import { Button, Pagination, Tooltip } from 'antd';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import PromotionViewModal from 'components/Dashboard/Promotions/PromotionViewModal';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Img } from 'react-image';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { getDefaultSize, getImageLink } from 'utilities/common';

function PromotionTable({
  intl,
  promotionData,
  products,
  sizes,
  total,
  onDeletePromotion,
  onEditPromotion,
  onChangePage,
}) {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [promotionInfo, setPromotionInfo] = useState(null);

  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };

  const handleRemovePromotion = () => {
    setIsRemoveModal(false);
    onDeletePromotion(promotionInfo);
  };

  const getProductCode = data => {
    let defaultCode = 0;
    if (data && sizes) {
      defaultCode = data.productCode;
      if (data.type !== '1') {
        const productVariants = sizes.filter(
          ({ classCode, item, state }) => state === 'A' && classCode === data.classCode && item === data.item,
        );
        if (productVariants.length > 0) {
          defaultCode = getDefaultSize(productVariants).productCode;
        }
      }
    }
    return { productCode: defaultCode || '' };
  };

  const getProduct = v => {
    const product = products.filter(p => p.id == v)[0];
    return product || {};
  };

  const renderPromotionImage = item => {
    if (item.picture) {
      return <Img src={getImageLink(item.picture)} alt="promotion" />;
    } else {
      return <div className="photo_name">{`${item.name ? item.name.charAt(0) : ''}`}</div>;
    }
  };

  return (
    <div className="promotion_table_layout">
      <div className="table_header">
        <div className="promotion_photo">
          <Apps />
        </div>
        <div className="name">
          <FormattedMessage id="Promotion name" />
        </div>
        <div className="type">
          <FormattedMessage id="Kind" />
        </div>
        <div className="destination">
          <FormattedMessage id="Destination" />
        </div>
        <div className="start_date">
          <FormattedMessage id="Start date" />
        </div>
        <div className="finish_date">
          <FormattedMessage id="Finish date" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {promotionData &&
          promotionData.map((item, index) => (
            <div className={`table_item type_state_${item.state}`} key={index}>
              <div className="promotion_photo">
                <div className="photo_wrapper">{renderPromotionImage(item)} </div>
              </div>
              <div className="name">
                <p>{item.name}</p>
              </div>
              <div className="type">
                <p>
                  {item.type === 'U'
                    ? intl.formatMessage({ id: 'Web View URL' })
                    : item.type === 'P'
                    ? intl.formatMessage({ id: 'Product page' })
                    : intl.formatMessage({ id: 'Offer page' })}
                </p>
              </div>
              <div className="destination">
                {sizes && products && sizes.length !== 0 && products.length !== 0 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        item.type === 'U' || item.type === 'O'
                          ? item.value
                          : `${getProductCode(getProduct(item.value)).productCode} - 
                          ${getProduct(item.value).name || ''}`,
                    }}
                  />
                )}
              </div>
              <div className="start_date">
                <p>{item.startAt ? `${moment(item.startAt).tz('America/Guatemala').format('LL')}` : ''}</p>
              </div>
              <div className="finish_date">
                <p>{item.endAt ? `${moment(item.endAt).tz('America/Guatemala').format('LL')}` : ''}</p>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit promotion' })}>
                  <Button
                    className="edit_btn"
                    onClick={() => {
                      onEditPromotion(item);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'See details' })}>
                  <Button
                    className="detail_btn"
                    onClick={() => {
                      setPromotionInfo(item);
                      setShowDetailModal(true);
                    }}
                  >
                    <RemoveRedEye />
                  </Button>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
      <PromotionViewModal
        products={products}
        sizes={sizes}
        isOpenModal={isShowDetailModal}
        promotionInfo={promotionInfo}
        onEdit={() => {
          setShowDetailModal(false);
          onEditPromotion(promotionInfo);
        }}
        onDelete={() => {
          setShowDetailModal(false);
          setIsRemoveModal(true);
        }}
        onCloseModal={() => setShowDetailModal(false)}
      />
      <DeleteConfirmModal
        isOpenModal={isRemoveModal}
        title={intl.formatMessage({ id: 'Do you want to remove the screen?' })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting the screen?',
        })}
        onCancel={() => setIsRemoveModal(false)}
        onOk={handleRemovePromotion}
      />
    </div>
  );
}

PromotionTable.defaultProps = {
  promotionData: [],
  products: [],
  sizes: [],
  total: '0',
};

export default compose(injectIntl)(PromotionTable);
