import { Apps, Edit, RemoveRedEye } from '@material-ui/icons';
import { Button, Pagination, Tooltip } from 'antd';
import AgentViewModal from 'components/Dashboard/OtsAgents/AgentViewModal';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Img } from 'react-image';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { NavLink } from 'react-router-dom';
import { getCurrency, getImageLink } from 'utilities/common';

function OtsMonitorTable({ intl, agentData, total, onChangePage }) {
  const [userInfo, setUserInfo] = useState(null);
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };

  return (
    <div className="ots_monitor_table_layout">
      <div className="table_header">
        <div className="user_avatar">
          <Apps />
        </div>
        <div className="code">
          <FormattedMessage id="Code" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="last_access">
          <FormattedMessage id="Last Access" />
        </div>
        <div className="last_order">
          <FormattedMessage id="Last Order" />
        </div>
        <div className="today_orders">
          <FormattedMessage id="Orders today" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {agentData &&
          agentData.map((item, index) => (
            <div className={`table_item type_state_${item.state === 'A'}`} key={index}>
              <div className="user_avatar">
                {!item.avatar && <div className="photo_wrapper" />}
                {item.avatar && <Img src={getImageLink(item.avatar, true)} />}
              </div>
              <div className="code">
                <p className="title">{item.eid || '-'}</p>
                <p className="subtitle">{`ID ${item.id}`}</p>
              </div>
              <div className="name">
                <p className="title">{`${item.firstName} ${item.lastName}`}</p>
                {item.online && (
                  <p className={`subtitle  ${item.takingOrder ? 'taking_order' : 'waiting_order'}`}>
                    {`${item.takingOrder ? 'Tomando pedido ahora' : 'Esperando pedido'}`}
                  </p>
                )}
              </div>
              <div className="last_access">
                {item.lastAccessedAt && (
                  <p className="title">
                    {`${moment(item.lastAccessedAt).tz('America/Guatemala').format('LL')} 
                      @${moment(item.lastAccessedAt).tz('America/Guatemala').format('HH:mm')}`}
                  </p>
                )}
                <p className="subtitle">IP {item.lastAccessedFrom || ''}</p>
              </div>
              <div className="last_order">
                {item.lastOrderedAt && (
                  <p className="title">
                    {`${moment(item.lastOrderedAt).tz('America/Guatemala').format('LL')} 
                      @${moment(item.lastOrderedAt).tz('America/Guatemala').format('HH:mm')}`}
                  </p>
                )}
                {item.lastOrder && (
                  <p className="subtitle">
                    {`Total del pedido: ${getCurrency().symbol}
                    ${parseFloat(item.lastOrder.paymentAmount / 100).toFixed(2) || ''}} - asignado a restaurante: ${
                      item.lastOrder.restaurant.description
                    }`}
                  </p>
                )}
              </div>
              <div className="today_orders">
                <p className="today_orders_count">{item.todayOrders || ''}</p>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'View user details' })}>
                  <Button
                    className="detail_btn"
                    onClick={() => {
                      setUserInfo(item);
                      setShowDetailModal(true);
                    }}
                  >
                    <RemoveRedEye />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit user' })}>
                  <NavLink to={`/dashboard/ots/${item.id}`}>
                    <Button className="edit_btn">
                      <Edit />
                    </Button>
                  </NavLink>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
      <AgentViewModal
        isOpenModal={isShowDetailModal}
        userInfo={userInfo}
        onCloseModal={() => setShowDetailModal(false)}
      />
    </div>
  );
}

OtsMonitorTable.defaultProps = {
  agentData: [],
  total: 0,
};

export default compose(injectIntl)(OtsMonitorTable);
