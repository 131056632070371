import { Apps, Edit, Map } from '@material-ui/icons';
import { Button, Pagination, Tooltip } from 'antd';
import downloadImg from 'assets/img/file_download.svg';
import AmbassadorMapModal from 'components/Dashboard/Ambassadors/AmbassadorMapModal';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Img } from 'react-image';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { NavLink } from 'react-router-dom';
import { getImageLink } from '../../../utilities/common';

function AmbassadorTable({ intl, ambassadorData, total, onChangePage }) {
  const [isShowMapModal, setIsShowMapModal] = useState(false);
  const [ambassadorInfo, setAmbassadorInfo] = useState(null);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };

  const handleShowMap = item => {
    setAmbassadorInfo(item);
    setIsShowMapModal(true);
  };
  return (
    <div className="ambassador_table_layout">
      <div className="table_header">
        <div className="user_avatar">
          <Apps />
        </div>
        <div className="code">
          <FormattedMessage id="Employee code" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="type">
          <FormattedMessage id="Ambassador Type" />
        </div>
        <div className="restaurant">
          <FormattedMessage id="Assigned restaurant" />
        </div>
        <div className="last_access">
          <FormattedMessage id="Last access to the system" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {ambassadorData &&
          ambassadorData.map((item, index) => (
            <div className={`table_item type_state_${item.state === 'A'}`} key={index}>
              <div className="user_avatar">
                {item.avatar && <Img className="photo_wrapper" src={getImageLink(item.avatar, true)} />}
                {!item.avatar && (
                  <div className="photo_name">
                    {`${item.firstName ? item.firstName.charAt(0) : ''}${item.lastName ? item.lastName.charAt(0) : ''}`}
                  </div>
                )}
              </div>
              <div className="code">
                <p className="title">{item.eid || ''}</p>
                <p className="subtitle">{item.id || ''}</p>
              </div>
              <div className="name">
                <p className="title">{`${item.firstName || ''} ${item.lastName || ''}`}</p>
              </div>
              <div className="type">
                <p className="title">
                  {item.type === 'E'
                    ? intl.formatMessage({ id: 'Ambassador' })
                    : intl.formatMessage({ id: 'Floating Ambassador' })}
                </p>
              </div>
              <div className="restaurant">
                {(!item.restaurants || item.restaurants.length === 0) && (
                  <p className="not_assigned title">
                    <FormattedMessage id="No assigned restaurant" />
                  </p>
                )}
                {item.restaurants &&
                  item.restaurants.length !== 0 &&
                  item.restaurants.map((r, ind) => (
                    <p key={ind} className="title">
                      {r.description || ''}
                    </p>
                  ))}
              </div>
              <div className="last_access">
                <p className="title">
                  {item.lastAccessedAt
                    ? `${moment(item.lastAccessedAt).tz('America/Guatemala').format('LL')}
                      @${moment(item.lastAccessedAt).tz('America/Guatemala').format('HH:mm')}`
                    : ''}
                </p>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Re-import ambassador' })}>
                  <Button className="file_download_btn">
                    <img src={downloadImg} alt="file download" />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'See current location' })}>
                  <Button className="map_btn" onClick={() => handleShowMap(item)}>
                    <Map />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit ambassador' })}>
                  <NavLink to={`/dashboard/ambassadors/${item.id}`}>
                    <Button className="edit_btn">
                      <Edit />
                    </Button>
                  </NavLink>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
      <AmbassadorMapModal
        isOpenModal={isShowMapModal}
        onCloseModal={() => setIsShowMapModal(false)}
        data={ambassadorInfo}
      />
    </div>
  );
}

AmbassadorTable.defaultProps = {
  ambassadorData: [],
  total: 0,
};

export default compose(injectIntl)(AmbassadorTable);
