import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';

function PhoneAlertModal({ isOpenModal, onCancel, onOk }) {
  const intl = useIntl();
  return (
    <ModalDlg className="delete_confirm_modal" visible={isOpenModal} width={574} handleCancel={onCancel} isCenter>
      <div className="modal_header">
        <h3>{intl.formatMessage({ id: 'PhoneDuplicateTitle' })}</h3>
        <p className="description">{intl.formatMessage({ id: 'PhoneDuplicateText' })}</p>
      </div>
      <div className="modal_footer">
        <div className="btn_wrapper">
          <p onClick={onCancel}>
            <FormattedMessage id="Cancel" />
          </p>
          <Button className="remove_btn" onClick={onOk}>
            <FormattedMessage id="Confirm" />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
}

PhoneAlertModal.defaultProps = {
  isOpenModal: false,
};

export default PhoneAlertModal;
