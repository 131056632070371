import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import OrderFilter from 'components/Dashboard/Orders/OrderFilter';
import OrdersTable from 'components/Dashboard/Orders/OrdersTable';
import OrderDetailModal from 'components/Dashboard/Orders/OrderDetailModal';
import OrderStatusModal from 'components/Dashboard/Orders/OrderStatusModal';
import Loading from 'components/Basic/Loading';
import toast from 'components/Basic/Toast';
import { useLazyGetOrderListQuery, useOrderProceedMutation, useCancelOrderMutation } from 'core/order/OrderService';
import { setOrdersData } from 'core/order/OrderSlice';
import { useAppDispatch, useAppSelector } from 'utilities/redux';

const Orders = () => {
  const requestWait = React.createRef(false);

  const [state, setState] = useState({
    orderDetail: null,
    isOpenModal: false,
    isOpenSarModal: false,
    searchText: '',
    offset: 0,
    limit: 10,
    stateFilter: '',
    restaurantFilter: '',
    filterQuery: '',
    searchFilterQuery: '',
    sort: '',
    startDate: '',
    endDate: '',
    isLoading: false,
  });
  const [stateUrl, setStateUrl] = useState('/v1/admin-area/orders/');

  const { list } = useAppSelector(state => state.orderReducer);

  const [getOrders] = useLazyGetOrderListQuery();
  const [orderProceed] = useOrderProceedMutation();
  const [cancelOrder] = useCancelOrderMutation();
  const dispatch = useAppDispatch();
  const [activeSearchItem, setActiveSearchItem] = React.useState(0);

  const intl = useIntl();

  useEffect(() => {
    const intervalId = setInterval(() => {
      getOrderList(
        state.offset,
        state.limit,
        state.filterQuery,
        state.sort,
        state.searchFilterQuery,
        stateUrl,
        state.searchText,
      );
    }, 10 * 1000);

    return () => clearInterval(intervalId);
  }, [state.offset, state.limit, state.filterQuery, state.sort, state.searchFilterQuery, stateUrl, state.searchText]);

  const onSelectOrder = (modalType, orderDetail) => {
    setState(prev => ({
      ...prev,
      orderDetail: orderDetail,
      isOpenModal: modalType === 'detail',
      isOpenSarModal: modalType === 'sar',
    }));
  };

  const getOrderList = async (offset, limit, filterQuery, sort, search, url, searchItem) => {
    requestWait.current = true;
    await getOrders({
      offset,
      limit,
      or: false,
      filter:
        stateUrl.length > 0 && state.searchText.length > 0
          ? convertToStructuredQueryString(filterQuery)
          : filterQuery + search,
      sort:
        stateUrl.length > 0 && state.searchText.length > 0
          ? convertSortToStructuredQueryString(sort || state.sort)
          : sort || state.sort,
      url,
      searchItem,
    })
      .unwrap()
      .then(res => {
        requestWait.current = false;
        dispatch(
          setOrdersData({
            list: res,
          }),
        );
        setState(prev => ({ ...prev, isLoading: false }));
      })
      .catch(() => {
        setState(prev => ({ ...prev, isLoading: false }));
      });
  };

  function convertToStructuredQueryString(input) {
    const filters = {};
    const parts = input.split('&');

    parts.forEach(part => {
      const [key, value] = part.split('=');
      const cleanKey = key.trim();

      if (cleanKey === 'filter') {
        if (value.startsWith('state in ')) {
          const states = value.replace('state in ', '').split(' ');
          filters.stateIn = states;
        } else if (value.startsWith('restaurant in ')) {
          const restaurantIds = value.replace('restaurant in ', '').split(' ');
          filters.restaurantIdIn = restaurantIds.map(Number);
        } else if (value.startsWith('dueTo between ')) {
          const dates = value.replace('dueTo between ', '').split(' ');
          if (dates.length === 2) {
            filters.dueTo = {
              from: dates[0],
              to: dates[1],
            };
          }
        }
      }
    });

    const newQueryString = [];

    if (filters.stateIn && filters.stateIn.length > 0) {
      filters.stateIn.forEach(state => {
        newQueryString.push(`filter[stateIn][]=${state}`);
      });
    }

    if (filters.restaurantIdIn && filters.restaurantIdIn.length > 0) {
      filters.restaurantIdIn.forEach(id => {
        newQueryString.push(`filter[restaurantIdIn][]=${id}`);
      });
    }

    if (filters.dueTo) {
      newQueryString.push(`filter[dueTo][from]=${filters.dueTo.from}`);
      newQueryString.push(`filter[dueTo][to]=${filters.dueTo.to}`);
    }

    return '&' + newQueryString.join('&');
  }

  function convertSortToStructuredQueryString(input) {
    const parts = input.split('&');
    const fields = [];

    parts.forEach(part => {
      const cleanPart = part.trim();
      if (!cleanPart) return;

      const orderDetails = cleanPart.split(' ');

      if (orderDetails.length > 0) {
        const field = orderDetails[0];
        fields.push(field);
      }
    });

    return fields.length > 0 ? `&sort=${fields.join(',')}` : '';
  }

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    await getOrders({
      offset,
      limit,
      or: false,
      filter:
        stateUrl.length > 0 && state.searchText.length > 0
          ? convertToStructuredQueryString(filterQuery || state.filterQuery)
          : filterQuery || state.filterQuery + state.searchFilterQuery,
      sort:
        stateUrl.length > 0 && state.searchText.length > 0
          ? convertSortToStructuredQueryString(sort || state.sort)
          : sort || state.sort,
      details: true,
      url: stateUrl,
      searchItem: state.searchText,
    })
      .unwrap()
      .then(res => {
        requestWait.current = false;
        dispatch(
          setOrdersData({
            list: res,
          }),
        );
        setState(prev => ({ ...prev, isLoading: false }));
      })
      .catch(() => {
        setState(prev => ({ ...prev, isLoading: false }));
      });
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleRestaurantFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=restaurant in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, restaurantFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, restaurantFilter: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      if (state.searchText) {
        if (!isNaN(state.searchText, 10) && state.searchText.length <= 8) {
          searchFilterQuery += `&filter=or.id eq ${state.searchText}`;
          if (state.searchText.length === 8) {
            searchFilterQuery += `&filter=or.order eq ${state.searchText}`;
          } else {
            searchFilterQuery += `&filter=or.order iLike %25${state.searchText}%25`;
          }
          searchFilterQuery += `&filter=ex.phone iLike %25${state.searchText}%25`;
        } else {
          searchFilterQuery += `&filter=ex.fullName iLike %25${state.searchText}%25`;
          searchFilterQuery += `&filter=or.deliveryAddress iLike %25${state.searchText}%25`;
          if (!isNaN(state.searchText.replace('+', ''))) {
            searchFilterQuery += `&filter=ex.phone iLike %25${state.searchText}%25`;
          }
        }
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));

      requestWait.current = true;

      await getOrders({
        offset: state.offset,
        limit: state.limit,
        or: false,
        filter:
          stateUrl.length > 0 && state.searchText.length > 0
            ? convertToStructuredQueryString(state.filterQuery)
            : state.filterQuery + searchFilterQuery,
        sort:
          stateUrl.length > 0 && state.searchText.length > 0
            ? convertSortToStructuredQueryString(state.sort)
            : state.sort,
        url: stateUrl,
        searchItem: state.searchText,
      })
        .unwrap()
        .then(res => {
          requestWait.current = false;
          dispatch(
            setOrdersData({
              list: res,
            }),
          );
          setState(prev => ({ ...prev, isLoading: false }));
        })
        .catch(() => {
          setState(prev => ({ ...prev, isLoading: false }));
        });
    }
  };

  const handleStartFilter = dateString => {
    setState(prev => ({ ...prev, startDate: dateString }));
  };

  const handleEndFilter = dateString => {
    setState(prev => ({ ...prev, endDate: dateString }));
  };

  const handleApplyFilter = async () => {
    let filterDate = '';
    if (state.startDate && state.endDate) {
      const start = moment(state.startDate).tz('America/Guatemala').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      const end = moment(state.endDate).tz('America/Guatemala').endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      filterDate = `&filter=dueTo between ${start} ${end}`;
    }
    setState(prev => ({
      ...prev,
      filterQuery: state.stateFilter + state.restaurantFilter + filterDate,
      isLoading: true,
    }));

    requestWait.current = true;

    await getOrders({
      offset: state.offset,
      limit: state.limit,
      or: false,
      filter:
        stateUrl.length > 0 && state.searchText.length > 0
          ? convertToStructuredQueryString(state.stateFilter + state.restaurantFilter + filterDate)
          : state.stateFilter + state.restaurantFilter + filterDate + state.searchFilterQuery,
      sort:
        stateUrl.length > 0 && state.searchText.length > 0
          ? convertSortToStructuredQueryString(state.sort)
          : state.sort,
      url: stateUrl,
      searchItem: state.searchText,
    })
      .unwrap()
      .then(res => {
        requestWait.current = false;
        dispatch(
          setOrdersData({
            list: res,
          }),
        );
        setState(prev => ({ ...prev, isLoading: false }));
      })
      .catch(() => {
        setState(prev => ({ ...prev, isLoading: false }));
      });
  };

  const handleSendToSar = async id => {
    setState(prev => ({ ...prev, isOpenSarModal: false }));
    await orderProceed({ id })
      .unwrap()
      .then(() => {
        toast.success({
          title: intl.formatMessage({
            id: 'Order is sent to SAR successfully',
          }),
        });
      })
      .catch(() => {
        toast.error({
          title: intl.formatMessage({
            id: 'Sending to SAR is failure',
          }),
        });
      });
  };

  const onCancelOrder = async id => {
    await cancelOrder({ id })
      .unwrap()
      .then(data => {
        setState(prev => ({ ...prev, orderDetail: { ...data, state: 'CD' } }));

        toast.success({ title: intl.formatMessage({ id: 'Order canceled successfully' }) });
      })
      .catch(() => {
        toast.error({ title: intl.formatMessage({ id: 'Order cancellation failure' }) });
      });
  };

  const updateUrl = newUrl => {
    setStateUrl(newUrl);
  };

  return (
    <div className="orders_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader
        type="order"
        searchText={state.searchText}
        onSearch={handleSearch}
        onKeyDownSearch={handleKeyDownSearch}
        updateUrl={updateUrl}
        activeSearchItem={activeSearchItem}
        setActiveSearchItem={setActiveSearchItem}
      />
      <div className="orders_filter_wrapper">
        <OrderFilter
          onStateFilter={handleStateFilter}
          onActiveRestaurant={handleRestaurantFilter}
          onStartFilterChange={handleStartFilter}
          onEndFilterChange={handleEndFilter}
          onSort={handleSort}
          onApplyFilter={handleApplyFilter}
        />
        <OrdersTable
          onSelectOrder={onSelectOrder}
          orderData={list.rows || []}
          onChangePage={handleChangePage}
          total={list.totalCount}
        />
        <OrderDetailModal
          orderDetail={state.orderDetail}
          isOpenModal={state.isOpenModal}
          onCloseModal={() => setState(prev => ({ ...prev, isOpenModal: false }))}
        />
        <OrderStatusModal
          orderDetail={state.orderDetail}
          isOpenModal={state.isOpenSarModal}
          onCloseModal={() => setState(prev => ({ ...prev, isOpenSarModal: false }))}
          onSendSAR={handleSendToSar}
          onCancelOrder={onCancelOrder}
        />
      </div>
    </div>
  );
};

export default Orders;
