import { DeleteOutline } from '@material-ui/icons';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import * as zxcvbnEsEsPackage from '@zxcvbn-ts/language-es-es';
import { Button, Form, Input, Switch } from 'antd';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import DropMenu from 'components/Basic/DropMenu';
import Loading from 'components/Basic/Loading';
import toast from 'components/Basic/Toast';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import {
  useCreateOperatorMutation,
  useDeleteOperatorMutation,
  useLazyGetOperatorQuery,
  useUpdateOperatorMutation,
} from 'core/operator/OperatorService';
import passwordComplexity from 'joi-password-complexity';
import { useEffect, useState } from 'react';
import { Img } from 'react-image';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'utilities/redux';
import { getImageLink } from '../../../utilities/common';
import { duplicationErrorCodes, validationErrorCodes } from '../../../utilities/constants';

const stateOptions = [
  { key: 'A', value: 'Active' },
  { key: 'I', value: 'Inactive' },
];
const genderOptions = [
  { key: 'M', value: 'Male' },
  { key: 'F', value: 'Female' },
];

const complexityOptions = {
  min: 20,
  max: 100,
  lowerCase: 5,
  upperCase: 5,
  numeric: 5,
  symbol: 3,
};

const rulesOption = {
  'passwordComplexity.tooShort': complexityOptions.min,
  'passwordComplexity.lowercase': complexityOptions.lowerCase,
  'passwordComplexity.uppercase': complexityOptions.upperCase,
  'passwordComplexity.numeric': complexityOptions.numeric,
  'passwordComplexity.symbol': complexityOptions.symbol,
  'passwordComplexity.tooLong': complexityOptions.max,
};

const options = {
  translations: { ...zxcvbnEnPackage.translations, ...zxcvbnEsEsPackage.translations },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
    ...zxcvbnEsEsPackage.dictionary,
  },
};

zxcvbnOptions.setOptions(options);

const OperatorAgentEdit = ({ match }) => {
  const { params } = match;
  const history = useHistory();
  const intl = useIntl();

  const { admin } = useAppSelector(state => state.loginReducer);

  const [state, setState] = useState({
    isRemoveModal: false,
    id: params.id,
    isShowPermissions: params.id != admin.id,
    gender: 'Género',
    userState: '',
    fileSrc: null,
    operatorInfo: {},
    settings: {
      orderAccess: false,
      restaurantAccess: false,
      catalogAccess: false,
      customerAccess: false,
      agentAccess: false,
      ambassadorAccess: false,
      operatorAccess: false,
      promotionAccess: false,
      couponAccess: false,
      notificationAccess: false,
      analyticsAccess: false,
      settingAccess: false,
      dealAccess: false,
      // rewardsAccess: false,
    },
    isLoading: false,
  });
  const [inputRef, setInputRef] = useState(null);
  const [errorState, setErrorState] = useState([]);

  useEffect(() => {
    if (inputRef) {
      setState(prev => ({ ...prev, fileElement: inputRef }));
    }
  }, [inputRef]);

  const [getOperator] = useLazyGetOperatorQuery();
  const [createOperator] = useCreateOperatorMutation();
  const [updateOperator] = useUpdateOperatorMutation();
  const [deleteOperator] = useDeleteOperatorMutation();

  useEffect(async () => {
    const { id } = state;
    if (id) {
      try {
        const res = await getOperator({ id }).unwrap();
        setState(prev => ({ ...prev, operatorInfo: res }));
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const openChooseFile = () => {
    state.fileElement.click();
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    const validFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
    if (event.target.files.length !== 0) {
      if (event.target.files[0].size > 1024 * 1024) {
        toast.error({
          title: intl.formatMessage({
            id: 'Image size should be less than 1MB',
          }),
        });
      } else if (file && !validFormats.includes(file.type)) {
        toast.error({
          title: intl.formatMessage({
            id: 'Incorrect file',
          }),
        });
        event.target.value = '';
        return;
      } else {
        handleChange('avatar', event.target.files[0]);
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          setState(prev => ({ ...prev, fileSrc: reader.result }));
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleChange = (field, value) => {
    const { operatorInfo } = state;
    setState(prev => ({ ...prev, operatorInfo: { ...operatorInfo, [field]: value } }));
  };

  const handlePasswordChange = (field, value) => {
    const { operatorInfo } = state;
    setState(prev => ({ ...prev, operatorInfo: { ...operatorInfo, [field]: value } }));
    if (value) {
      const validatePassword = passwordComplexity(complexityOptions).validate(value);
      const validateErrors = validatePassword?.error?.details?.map(item => item.type);
      if (validateErrors?.length) {
        setErrorState(validateErrors || []);
      } else {
        setErrorState([]);
        const b = zxcvbn(value);
        if (b.score !== 4 || b.sequence?.length > 1) {
          setErrorState(['incorrectDataInPassword']);
        }
      }
    } else {
      setErrorState([]);
    }
  };

  const handleFilter = (filterType, key) => {
    if (filterType === 'gender') {
      handleChange('gender', genderOptions[key].key);
      setState(prev => ({ ...prev, gender: genderOptions[key].value }));
    } else if (filterType === 'state') {
      handleChange('state', stateOptions[key].key);
      setState(prev => ({ ...prev, userState: stateOptions[key].value }));
    }
  };

  const handleRemoveAgent = async () => {
    setState(prev => ({ ...prev, isRemoveModal: false }));
    const { id } = state;
    if (id) {
      try {
        await deleteOperator({ id }).unwrap();
        toast.success({
          title: intl.formatMessage({
            id: 'Operator Agent is deleted successfully!',
          }),
        });
        history.push(`/dashboard/operators`);
      } catch (e) {
        toast.error({
          title: intl.formatMessage({
            id: 'Deleting Operator Agent is failure!',
          }),
        });
      }
    }
  };

  const handleOperatorAgent = async () => {
    if (errorState && errorState?.length) {
      toast.error({
        title: intl.formatMessage({
          id: 'Invalid password!',
        }),
      });
      return;
    }
    const { operatorInfo, id, settings } = state;
    if (
      operatorInfo.password === operatorInfo.confirm_password ||
      (!operatorInfo.password && !operatorInfo.confirm_password)
    ) {
      if (!id) {
        const newSettings = {};
        Object.keys(settings).map(key => {
          newSettings[key] = !!operatorInfo[key];
        });

        const newOperatorInfo = { ...operatorInfo, ...newSettings };

        setState(prev => ({ ...prev, isLoading: true }));
        try {
          const formData = new FormData();
          for (const key in newOperatorInfo) {
            formData.append(key, newOperatorInfo[key]);
          }

          await createOperator({ formData }).unwrap();

          setState(prev => ({ ...prev, isLoading: false }));
          toast.success({
            title: intl.formatMessage({
              id: 'New Operator Agent is created successfully!',
            }),
          });
          history.push(`/dashboard/operators`);
        } catch (e) {
          setState(prev => ({ ...prev, isLoading: false }));
          if (validationErrorCodes.includes(e.data.code) || duplicationErrorCodes.includes(e.data.code)) {
            toast.error({
              title: intl.formatMessage({
                id: e.data.details[0].message,
              }),
            });
          } else {
            toast.error({
              title: intl.formatMessage({
                id: 'Creating Operator Agent is a failure!',
              }),
            });
          }
        }
      } else {
        const newSettings = {};
        Object.keys(settings).map(key => {
          newSettings[key] = !!operatorInfo[key];
        });

        const newOperatorInfo = { ...operatorInfo, ...newSettings };

        setState(prev => ({ ...prev, isLoading: true }));
        try {
          const formDataUpdate = new FormData();
          for (const key in newOperatorInfo) {
            formDataUpdate.append(key, newOperatorInfo[key]);
          }

          await updateOperator({ id, formDataUpdate }).unwrap();

          setState(prev => ({ ...prev, isLoading: false }));
          toast.success({
            title: intl.formatMessage({
              id: 'Operator Agent is updated successfully!',
            }),
          });
        } catch (e) {
          setState(prev => ({ ...prev, isLoading: false }));
          if (validationErrorCodes.includes(e.data.code)) {
            toast.error({
              title: intl.formatMessage({
                id: e.data.details[0].message,
              }),
            });
          } else {
            toast.error({
              title: intl.formatMessage({
                id: 'Updating Operator Agent is failure!',
              }),
            });
          }
        }
      }
    } else {
      toast.error({
        title: intl.formatMessage({
          id: 'Password is not matched with confirm password!',
        }),
      });
    }
  };

  const onSettingChange = (checked, key) => {
    const { operatorInfo } = state;
    const initialOperatorInfo = { ...operatorInfo };
    initialOperatorInfo[key] = checked;
    setState(prev => ({ ...prev, operatorInfo: { ...initialOperatorInfo } }));
  };

  const { isShowPermissions, operatorInfo } = state;
  let isEdit = false;
  if (match.params && match.params.id) {
    isEdit = true;
  }
  return (
    <div className="operator_agent_edit_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="operator_agent_edit" isEdit={isEdit} onCreateOperatorAgent={handleOperatorAgent} />
      <div className="operator_agent_wrapper">
        <div className="card profile_outline_wrapper">
          <h3>
            <FormattedMessage id="Profile outlines" />
          </h3>
          <p className="profile_outline">
            <FormattedMessage id="Operator Description" />
          </p>
          {isEdit && (
            <div className="remove_user_wrapper">
              <Button
                onClick={() => setState(prev => ({ ...prev, isRemoveModal: true }))}
                disabled={!isShowPermissions || operatorInfo.eid == 1}
              >
                <FormattedMessage id="Delete user" />
                <DeleteOutline />
              </Button>
            </div>
          )}
        </div>
        <div className="card">
          <h3>
            <FormattedMessage id="User Profile" />
          </h3>
          <div className="user_avatar" onClick={openChooseFile}>
            {!state.fileSrc && !operatorInfo.avatar && <div className="avatar" />}
            {(operatorInfo.avatar || state.fileSrc) && (
              <div className="avatar">
                <Img
                  src={state.fileSrc ? state.fileSrc : getImageLink(operatorInfo.avatar, true)}
                  alt="avatar"
                  className="avatar"
                />
              </div>
            )}
            <p className="title">
              <FormattedMessage id="User avatar" />
            </p>
            <input
              type="file"
              className="file_input"
              accept="image/*, .svg"
              ref={setInputRef}
              onChange={handleFileChange}
            />
          </div>
          <Form className="basic_info_form">
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Name" />
              </p>
              <Input
                placeholder="Nombre"
                value={operatorInfo.firstName || ''}
                onChange={e => handleChange('firstName', e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Surnames" />
              </p>
              <Input
                placeholder="Apellidos"
                value={operatorInfo.lastName || ''}
                onChange={e => handleChange('lastName', e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Gender" />
              </p>
              <DropMenu
                items={genderOptions}
                onMenu={key => handleFilter('gender', key)}
                placeHolder={state.gender}
                className="dropdown"
                defaultValue={operatorInfo.gender || ''}
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Email" />
              </p>
              <Input
                type="email"
                placeholder="Correo electrónico"
                value={operatorInfo.email || ''}
                onChange={e => handleChange('email', e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Employee code" />
              </p>
              <Input
                placeholder="Código de empleado"
                value={operatorInfo.eid || ''}
                onChange={e => handleChange('eid', e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <div className="password_label">
                <p className="title">
                  <FormattedMessage id="Password" />
                </p>
                <p className="note">
                  <FormattedMessage id="Leave blank to not change" />
                </p>
              </div>
              <Input.Password
                type="password"
                placeholder="Contraseña"
                value={operatorInfo.password || ''}
                onChange={e => handlePasswordChange('password', e.target.value)}
              />
              {errorState?.map((item, index) => {
                return (
                  <div key={index} className="error_message">
                    {intl.formatMessage({ id: `${item}` }, { rule: rulesOption[item] })}
                  </div>
                );
              })}
            </Form.Item>
            <Form.Item>
              <div className="password_label">
                <p className="title">
                  <FormattedMessage id="Confirm Password" />
                </p>
                <p className="note">
                  <FormattedMessage id="Leave blank to not change" />
                </p>
              </div>
              <Input
                type="password"
                placeholder="Confirmar contraseña"
                value={operatorInfo.confirm_password || ''}
                onChange={e => handleChange('confirm_password', e.target.value)}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="card">
          <h3>
            <FormattedMessage id="Other settings" />
          </h3>
          <div className="user_state">
            <p>
              <FormattedMessage id="State" />
            </p>
            <DropMenu
              className="dropdown"
              items={stateOptions}
              onMenu={key => handleFilter('state', key)}
              placeholder={state.userState || 'Active'}
              defaultValue={operatorInfo.state || 'A'}
            />
          </div>
          <div className="setting_wrapper">
            {Object.keys(state.settings).map(key => (
              <div className="setting" key={key}>
                <p className="title">{intl.formatMessage({ id: `${key}` })}</p>
                <Switch
                  disabled={!isShowPermissions || operatorInfo.eid == 1}
                  onChange={checked => onSettingChange(checked, key)}
                  checked={operatorInfo[key]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <DeleteConfirmModal
        isOpenModal={state.isRemoveModal}
        title={intl.formatMessage({
          id: 'Do you want to delete the user?',
        })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting user?',
        })}
        onCancel={() => setState(prev => ({ ...prev, isRemoveModal: false }))}
        onOk={handleRemoveAgent}
      />
    </div>
  );
};

export default OperatorAgentEdit;
