import React from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import Offers from './Offers';
import Campaign from './Campaign';
import Configuration from './Configuration';
import OffersDraft from './OffersDraft';
import { FormattedMessage } from 'react-intl';

const Deals: React.FC = () => {
  if (window.location.pathname === '/dashboard/deals') {
    return <Redirect to={'/dashboard/deals/campaign'} />;
  }
  return (
    <div className="deals_header_layout">
      <div className="deals_config_header_wrapper">
        <div className="sar_config_list_header">
          <div className="header_title">
            <FormattedMessage id="Promociones - Pantallas de Acción" />
          </div>
        </div>
        <div className="menu_items">
          <nav className="menu_nav">
            <NavLink to={'/dashboard/deals/campaign'} activeClassName={'active'}>
              <FormattedMessage id={'Campaign'} />
            </NavLink>
          </nav>
          <nav className="menu_nav">
            <NavLink to={'/dashboard/deals/offers'} activeClassName={'active'}>
              <FormattedMessage id={'Offers'} />
            </NavLink>
          </nav>
          <nav className="menu_nav">
            <NavLink to={'/dashboard/deals/configuration'} activeClassName={'active'}>
              <FormattedMessage id={'Configuration'} />
            </NavLink>
          </nav>
          <nav className="menu_nav">
            <NavLink to={'/dashboard/deals/offers-draft'} activeClassName={'active'}>
              <FormattedMessage id={'Offers draft'} />
            </NavLink>
          </nav>
        </div>
      </div>
      <div className={'deals_layout_content'}>
        <div className={'deals_layout_content_box'}>
          <Switch>
            <Route exact path={'/dashboard/deals/campaign'} component={Campaign} />
            <Route exact path={['/dashboard/deals/offers', '/dashboard/deals/offers/:offerId']} component={Offers} />
            <Route exact path={'/dashboard/deals/configuration'} component={Configuration} />
            <Route
              exact
              path={['/dashboard/deals/offers-draft', '/dashboard/deals/offers-draft/:draftId']}
              component={OffersDraft}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Deals;
