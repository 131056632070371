import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import { Button, Input, Spin } from 'antd';
import Icon from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { FormattedMessage, useIntl } from 'react-intl';
import { MailOutline, LockOutlined, ArrowForward } from '@material-ui/icons';
import logoImg from 'assets/img/logo.svg';
import { getCountry } from 'utilities/common';
import { useLoginMutation, useLogoutMutation, useCheckAdminEmailMutation } from 'core/auth/LoginService';
import { setAdminData } from 'core/auth/LoginSlice';
import { useAppSelector, useAppDispatch } from 'utilities/redux';
import { authenticationErrorCodes } from '../../utilities/constants';

const { Search } = Input;

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Login = ({ form }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [login] = useLoginMutation();
  const [logout] = useLogoutMutation();
  const [check] = useCheckAdminEmailMutation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const intl = useIntl();

  const { admin } = useAppSelector(state => state.loginReducer);

  const handleQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('logout');
    if (query) {
      logoutByQuery(query);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsLoading(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    setIsLoading(false);
    handleQueryParams();
  }, [location]);

  useEffect(() => {
    if (admin.accessToken) {
      history.push('/dashboard/overview');
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setIsLoading(true);
        try {
          const res = await login({
            email: values.email,
            password: values.password,
          }).unwrap();
          dispatch(
            setAdminData({
              admin: res,
            }),
          );
          setIsLoading(false);
          history.push('/dashboard/overview');
        } catch (error) {
          if (authenticationErrorCodes.includes(error.data.code)) {
            setErrMsg('Authentication error');
          } else {
            setErrMsg('Cannot connect to internet');
          }
          setIsLoading(false);
        }
      }
    });
  };

  const handleSubmitSSO = async e => {
    e.preventDefault();
    setErrMsg('');
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setIsLoading(true);
        try {
          await check({
            email: values.email,
          }).unwrap();
          globalSSO();
        } catch (error) {
          setErrMsg('NotFoundErrorEmail');
          setIsLoading(false);
        }
      }
    });
  };

  const checkSSOEmail = async (value, e) => {
    e.preventDefault();
    setErrMsg('');
    setIsLoading(true);
    try {
      await check({
        email: value,
      }).unwrap();
      globalSSO();
    } catch (error) {
      setErrMsg('NotFoundErrorEmail');
      setIsLoading(false);
    }
  };

  const globalSSO = async () => {
    await logout({}).unwrap();
    document.cookie = 'connect.sid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    window.location.href = `${process.env.REACT_APP_SERVER}v0/auth/mcd-global`;
  };

  const logoutByQuery = async query => {
    await logout({}).unwrap();
    if (query === 'email') {
      setErrMsg('LoginFailureError');
    } else {
      setErrMsg('AnotherSession');
    }
    const url = new URL(window.location);
    url.searchParams.delete('logout');
    window.history.replaceState(null, '', url.toString());
  };

  const { getFieldDecorator } = form;
  return (
    <div className="login_layout">
      {/* <Spin indicator={antIcon} spinning={isLoading}> */}
      <div className="left_wrapper">
        <div className="logo-wrapper">
          <Img src={logoImg} alt="logo" />
        </div>
        <div className="login_wrapper">
          <p className="title">
            <FormattedMessage id="Administration system" />
          </p>
          <p className="description">
            {getCountry()} / <FormattedMessage id="Backend" />
          </p>
          <Form onSubmit={process.env.REACT_APP_SSO === 'true' ? handleSubmitSSO : handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: intl.formatMessage({
                      id: 'The input is not valid email!',
                    }),
                  },
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'Please input your email!',
                    }),
                  },
                ],
              })(<Input prefix={<MailOutline />} placeholder="Correo electrónico" />)}
            </Form.Item>
            {process.env.REACT_APP_SSO !== 'true' && (
              <>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'Please input your Password!',
                        }),
                      },
                    ],
                  })(<Input type="password" prefix={<LockOutlined />} placeholder="Contraseña" />)}
                </Form.Item>
              </>
            )}

            {errMsg && (
              <p className="error_msg">
                <FormattedMessage id={`${errMsg}`} />
              </p>
            )}
            <Form.Item className="login-form-btn">
              <Button htmlType="submit" loading={isLoading}>
                <FormattedMessage id="Login" />
                <ArrowForward />
              </Button>
            </Form.Item>
          </Form>
          {process.env.REACT_APP_SSO !== 'true' && (
            <>
              <div className="sso_btn">
                <FormattedMessage id="Enter with Global SSO" />
              </div>

              <Search
                prefix={<MailOutline />}
                placeholder="Correo electrónico"
                enterButton="Iniciar sesión"
                size="middle"
                loading={isLoading}
                className="sso_search_input"
                onSearch={(v, e) => checkSSOEmail(v, e)}
              />
            </>
          )}
        </div>
        <div className="footer">
          <p className="copy_right">
            <FormattedMessage id="McDonald’s © {year}" values={{ year: new Date().getFullYear() }} />
          </p>
        </div>
      </div>
      {/* </Spin> */}
    </div>
  );
};

Login.defaultProps = {
  form: {},
};

export default compose(Form.create({ name: 'login_form' }))(Login);
