import { DeleteOutline } from '@material-ui/icons';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import * as zxcvbnEsEsPackage from '@zxcvbn-ts/language-es-es';
import { Button, Form, Input } from 'antd';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import DropMenu from 'components/Basic/DropMenu';
import Loading from 'components/Basic/Loading';
import toast from 'components/Basic/Toast';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import {
  useCreateAgentMutation,
  useDeleteAgentMutation,
  useLazyGetAgentQuery,
  useUpdateAgentMutation,
} from 'core/agent/AgentService';
import passwordComplexity from 'joi-password-complexity';
import { useEffect, useState } from 'react';
import { Img } from 'react-image';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getImageLink } from '../../../utilities/common';
import { duplicationErrorCodes, validationErrorCodes } from '../../../utilities/constants';

const stateOptions = [
  { key: 'A', value: 'Active' },
  { key: 'I', value: 'Inactive' },
];
const genderOptions = [
  { key: 'M', value: 'Male' },
  { key: 'F', value: 'Female' },
];

const complexityOptions = {
  min: 20,
  max: 100,
  lowerCase: 5,
  upperCase: 5,
  numeric: 5,
  symbol: 3,
};

const rulesOption = {
  'passwordComplexity.tooShort': complexityOptions.min,
  'passwordComplexity.lowercase': complexityOptions.lowerCase,
  'passwordComplexity.uppercase': complexityOptions.upperCase,
  'passwordComplexity.numeric': complexityOptions.numeric,
  'passwordComplexity.symbol': complexityOptions.symbol,
  'passwordComplexity.tooLong': complexityOptions.max,
};

const options = {
  translations: { ...zxcvbnEnPackage.translations, ...zxcvbnEsEsPackage.translations },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
    ...zxcvbnEsEsPackage.dictionary,
  },
};

zxcvbnOptions.setOptions(options);

const OtsAgentEdit = ({ match }) => {
  const { params } = match;

  const intl = useIntl();
  const history = useHistory();

  const [state, setState] = useState({
    isRemoveModal: false,
    id: params.id,
    gender: 'Género',
    userState: '',
    fileSrc: null,
    agentInfo: {},
    isLoading: false,
  });
  const [inputRef, setInputRef] = useState(null);
  const [errorState, setErrorState] = useState([]);

  const [getAgent] = useLazyGetAgentQuery();
  const [createAgent] = useCreateAgentMutation();
  const [updateAgent] = useUpdateAgentMutation();
  const [deleteAgent] = useDeleteAgentMutation();

  useEffect(() => {
    if (inputRef) {
      setState(prev => ({ ...prev, fileElement: inputRef }));
    }
  }, [inputRef]);

  useEffect(async () => {
    const { id } = state;
    if (id) {
      try {
        const res = await getAgent({ id }).unwrap();
        setState(prev => ({ ...prev, agentInfo: res }));
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const openChooseFile = () => {
    state.fileElement.click();
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    const validFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
    if (event.target.files.length !== 0) {
      if (event.target.files[0].size > 1024 * 1024) {
        toast.error({
          title: intl.formatMessage({
            id: 'Image size should be less than 1MB',
          }),
        });
      } else if (file && !validFormats.includes(file.type)) {
        toast.error({
          title: intl.formatMessage({
            id: 'Incorrect file',
          }),
        });
        event.target.value = '';
        return;
      } else {
        handleChange('avatar', event.target.files[0]);
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          setState(prev => ({ ...prev, fileSrc: reader.result }));
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleChange = (field, value) => {
    const { agentInfo } = state;
    setState(prev => ({ ...prev, agentInfo: { ...agentInfo, [field]: value } }));
  };

  const handlePasswordChange = (field, value) => {
    const { agentInfo } = state;
    setState(prev => ({ ...prev, agentInfo: { ...agentInfo, [field]: value } }));
    if (value) {
      const validatePassword = passwordComplexity(complexityOptions).validate(value);
      const validateErrors = validatePassword?.error?.details?.map(item => item.type);
      if (validateErrors?.length) {
        setErrorState(validateErrors || []);
      } else {
        setErrorState([]);
        const b = zxcvbn(value);
        if (b.score !== 4 || b.sequence?.length > 1) {
          setErrorState(['incorrectDataInPassword']);
        }
      }
    } else {
      setErrorState([]);
    }
  };

  const handleFilter = (filterType, key) => {
    if (filterType === 'gender') {
      handleChange('gender', genderOptions[key].key);
      setState(prev => ({ ...prev, gender: genderOptions[key].value }));
    } else if (filterType === 'state') {
      handleChange('state', stateOptions[key].key);
      setState(prev => ({ ...prev, userState: stateOptions[key].value }));
    }
  };

  const handleRemoveAgent = async () => {
    setState(prev => ({ ...prev, isRemoveModal: false }));
    const { id } = state;
    if (id) {
      try {
        await deleteAgent({ id }).unwrap();
        toast.success({
          title: intl.formatMessage({
            id: 'Ots Agent is deleted successfully!',
          }),
        });
        history.push(`/dashboard/ots`);
      } catch (err) {
        toast.error({
          title: intl.formatMessage({
            id: 'Deleting Ots Agent is failure!',
          }),
        });
      }
    }
  };

  const handleOtsAgent = async () => {
    if (errorState && errorState?.length) {
      toast.error({
        title: intl.formatMessage({
          id: 'Invalid password!',
        }),
      });
      return;
    }
    const { agentInfo, id } = state;

    const formData = new FormData();
    for (const key in agentInfo) {
      formData.append(key, agentInfo[key]);
    }

    if (agentInfo.password === agentInfo.confirm_password || (!agentInfo.password && !agentInfo.confirm_password)) {
      if (!id) {
        setState(prev => ({ ...prev, isLoading: true }));
        try {
          await createAgent({ formData }).unwrap();
          setState(prev => ({ ...prev, isLoading: false }));
          toast.success({
            title: intl.formatMessage({
              id: 'New Ots Agent is created successfully!',
            }),
          });
          history.push(`/dashboard/ots`);
        } catch (e) {
          setState(prev => ({ ...prev, isLoading: false }));
          if (validationErrorCodes.includes(e.data.code) || duplicationErrorCodes.includes(e.data.code)) {
            toast.error({
              title: intl.formatMessage({
                id: e?.data?.details[0]?.message,
              }),
            });
          } else if (e?.data?.details[0]?.message === 'File too large') {
            toast.error({
              title: intl.formatMessage({
                id: 'File too large',
              }),
            });
          } else {
            toast.error({
              title: intl.formatMessage({
                id: 'Creating Ots Agent is a failure!',
              }),
            });
          }
        }
      } else {
        setState(prev => ({ ...prev, isLoading: true }));
        try {
          await updateAgent({ id, formData }).unwrap();
          setState(prev => ({ ...prev, isLoading: false }));
          toast.success({
            title: intl.formatMessage({
              id: 'Ots Agent is updated successfully!',
            }),
          });
        } catch (e) {
          setState(prev => ({ ...prev, isLoading: false }));
          if (validationErrorCodes.includes(e.data.code)) {
            toast.error({
              title: intl.formatMessage({
                id: e?.data?.details[0]?.message,
              }),
            });
          } else if (e?.data?.details[0]?.message === 'File too large') {
            toast.error({
              title: intl.formatMessage({
                id: 'File too large',
              }),
            });
          } else {
            toast.error({
              title: intl.formatMessage({
                id: 'Updating Ots Agent is failure!',
              }),
            });
          }
        }
      }
    } else {
      toast.error({
        title: intl.formatMessage({
          id: 'Password is not matched with confirm password!',
        }),
      });
    }
  };

  let isEdit = false;
  if (match.params && match.params.id) {
    isEdit = true;
  }

  const { agentInfo } = state;
  return (
    <div className="ots_agent_edit_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="ots_agent_edit" isEdit={isEdit} onCreateOtsAgent={handleOtsAgent} />
      <div className="ots_agent_wrapper">
        <div className="card profile_outline_wrapper">
          <h3>
            <FormattedMessage id="Profile outlines" />
          </h3>
          <p className="profile_outline">
            <FormattedMessage id="OTS Agent Profile Description" />
          </p>
          {isEdit && (
            <div className="remove_user_wrapper">
              <Button onClick={() => setState(prev => ({ ...prev, isRemoveModal: true }))}>
                <FormattedMessage id="Delete user" />
                <DeleteOutline />
              </Button>
            </div>
          )}
        </div>
        <div className="card">
          <h3>
            <FormattedMessage id="User Profile" />
          </h3>
          <div className="user_avatar" onClick={openChooseFile}>
            {!state.fileSrc && !agentInfo.avatar && <div className="avatar" />}
            {(agentInfo.avatar || state.fileSrc) && (
              <div className="avatar">
                <Img src={state.fileSrc ? state.fileSrc : getImageLink(agentInfo.avatar, true)} alt="avatar" />
              </div>
            )}
            <p className="title">
              <FormattedMessage id="User avatar" />
            </p>
            <input
              type="file"
              className="file_input"
              accept="image/*, .svg"
              ref={setInputRef}
              onChange={handleFileChange}
            />
          </div>
          <Form className="basic_info_form">
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Name" />
              </p>
              <Input
                placeholder="Nombre"
                value={agentInfo.firstName || ''}
                onChange={e => handleChange('firstName', e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Surnames" />
              </p>
              <Input
                placeholder="Apellidos"
                value={agentInfo.lastName || ''}
                onChange={e => handleChange('lastName', e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Gender" />
              </p>
              <DropMenu
                items={genderOptions}
                onMenu={key => handleFilter('gender', key)}
                placeHolder={state.gender}
                className="dropdown"
                defaultValue={agentInfo.gender || ''}
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Employee code" />
              </p>
              <Input
                placeholder="Código de empleado"
                value={agentInfo.eid || ''}
                onChange={e => handleChange('eid', e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <div className="password_label">
                <p className="title">
                  <FormattedMessage id="Password" />
                </p>
                <p className="note">
                  <FormattedMessage id="Leave blank to not change" />
                </p>
              </div>
              <Input.Password
                type="password"
                placeholder="Contraseña"
                value={agentInfo.password || ''}
                onChange={e => handlePasswordChange('password', e.target.value)}
              />
              {errorState?.map((item, index) => {
                return (
                  <div key={index} className="error_message">
                    {intl.formatMessage({ id: `${item}` }, { rule: rulesOption[item] })}
                  </div>
                );
              })}
            </Form.Item>
            <Form.Item>
              <div className="password_label">
                <p className="title">
                  <FormattedMessage id="Confirm Password" />
                </p>
                <p className="note">
                  <FormattedMessage id="Leave blank to not change" />
                </p>
              </div>
              <Input
                type="password"
                placeholder="Confirmar contraseña"
                value={agentInfo.confirm_password || ''}
                onChange={e => handleChange('confirm_password', e.target.value)}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="card">
          <h3>
            <FormattedMessage id="Other settings" />
          </h3>
          <div className="user_state">
            <p>
              <FormattedMessage id="State" />
            </p>
            <DropMenu
              className="dropdown"
              items={stateOptions}
              onMenu={key => handleFilter('state', key)}
              placeholder={state.userState || 'Active'}
              defaultValue={agentInfo.state || 'A'}
            />
          </div>
          <div className="today_summary">
            <h3>
              <FormattedMessage id="Today's summary" />
            </h3>
            <div>
              <div className="order_taken">
                <h3>{agentInfo.todayOrders || 0}</h3>
                <p>
                  <FormattedMessage id="Orders taken" />
                </p>
              </div>
              <div className="connection_hours">
                <h3>-</h3>
                <p>
                  <FormattedMessage id="Connection hours" />
                </p>
              </div>
            </div>
            <div>
              <div className="order_taken_hours">
                <h3>{agentInfo.todayCallTime ? parseFloat(agentInfo.todayCallTime / 3600000).toFixed(2) : 0}</h3>
                <p>
                  <FormattedMessage id="Hours taking orders" />
                </p>
              </div>
              <div className="call_minutes">
                <h3>{agentInfo.todayCallTime ? parseFloat(agentInfo.todayLongestCall / 60000).toFixed(2) : 0}</h3>
                <p>
                  <FormattedMessage id="Minutes on longest call" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmModal
        isOpenModal={state.isRemoveModal}
        title={intl.formatMessage({
          id: 'Do you want to delete the user?',
        })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting user?',
        })}
        onCancel={() => setState(prev => ({ ...prev, isRemoveModal: false }))}
        onOk={handleRemoveAgent}
      />
    </div>
  );
};

export default OtsAgentEdit;
