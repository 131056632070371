import { Form } from '@ant-design/compatible';
import { ArrowDropDown, ArrowForward } from '@material-ui/icons';
import { Button, DatePicker, Input, Select, Switch, TimePicker } from 'antd';
import placeHolderImg from 'assets/img/placeholder.svg';
import Loading from 'components/Basic/Loading';
import ModalDlg from 'components/Basic/ModalDlg';
import toast from 'components/Basic/Toast';
import { useLazyGetOffersListQuery } from 'core/offers/OffersService';
import * as dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useRef, useState } from 'react';
import { Img } from 'react-image';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';
import { getDefaultSize, getImageLink } from 'utilities/common';
import { converDateWithResetTime, convertToDayJsTime } from 'utilities/transformDateTime';

const { Option } = Select;
dayjs.extend(utc);
dayjs.extend(timezone);

function PromotionNewModal({
  isCreatePromotion,
  products,
  sizes,
  promotion,
  intl,
  form,
  isOpenModal,
  onCloseModal,
  onSubmitPromotion,
}) {
  const actionTypes = [
    { value: 'U', label: intl.formatMessage({ id: 'Web View URL' }) },
    { value: 'P', label: intl.formatMessage({ id: 'Product page' }) },
    { value: 'O', label: intl.formatMessage({ id: 'Offer page' }) },
  ];
  const promotionOrders = new Array(20).fill().map((_, index) => index + 1);
  const [promotionInfo, setPromotionInfo] = useState({});
  const fileRef = useRef(null);
  const [fileSrc, setFileSrc] = useState(null);
  const [dimentions, setDimentions] = useState({
    type: '',
    width: 0,
    height: 0,
  });
  const [isLoadProduct, setIsLoadProduct] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const { getFieldDecorator } = form;
  const [getOffersList] = useLazyGetOffersListQuery();
  const [dealsData, setDealsdata] = useState([]);

  useEffect(() => {
    if (isCreatePromotion) {
      form.resetFields();
      setFileSrc(null);
      setDimentions({
        type: '',
        width: 0,
        height: 0,
      });
      setIsSubmit(false);
      setPromotionInfo({
        name: '',
        type: 'U',
        webUrl: '',
        product: '',
        state: 'A',
        startAt: null,
        endAt: null,
        picture: null,
      });
    } else {
      form.resetFields();
      setFileSrc(null);
      setDimentions({
        type: '',
        width: 0,
        height: 0,
      });
      setIsSubmit(false);
      setPromotionInfo({ ...promotion });
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (products.length !== 0 && promotion && promotion.value && promotion.type === 'P') {
      const product = products.filter(p => p.id == promotion.value)[0];
      setSelectedProduct(product);
    }
  }, [promotion, products]);

  useEffect(() => {
    if (products.length > 5) {
      setIsLoadProduct(false);
    }
  }, [products]);

  const openChooseFile = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    if (promotionInfo.type === 'O') {
      getDealData();
    }
    return;
  }, [promotionInfo]);

  const handleFileChange = event => {
    const file = event.target.files[0];
    const validFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
    if (event.target.files.length !== 0) {
      if (event.target.files[0].size > 1024 * 1024) {
        toast.error({
          title: intl.formatMessage({
            id: 'Image size should be less than 1MB',
          }),
        });
      } else if (file && !validFormats.includes(file.type)) {
        toast.error({
          title: intl.formatMessage({
            id: 'Incorrect file',
          }),
        });
        event.target.value = '';
        return;
      } else {
        setPromotionInfo({ ...promotionInfo, picture: event.target.files[0] });
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            setDimentions({
              width: img.width,
              height: img.height,
              type: file.type,
            });
          };
          img.src = reader.result;
          setFileSrc(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
    event.target.value = '';
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsSubmit(true);
    form.validateFieldsAndScroll((err, values) => {
      const newStartDateAt = converDateWithResetTime(values.startAt.toString(), true);
      const newEndDateAt = converDateWithResetTime(values.endAt.toString(), false);

      if (!err && promotionInfo.picture) {
        onSubmitPromotion({
          name: values.name,
          type: values.type,
          value: values.type === 'U' ? values.webUrl : values.type === 'P' ? values.product : values.offer,
          startAt: newStartDateAt.toString(),
          endAt: newEndDateAt.toString(),
          startTimeAt: convertToDayJsTime(values.startTimeAt),
          endTimeAt: convertToDayJsTime(values.endTimeAt),
          picture: promotionInfo.picture,
          state: values.state ? 'A' : 'I',
          order: values.order ? values.order : null,
        });
      }
    });
  };

  const handleActionType = type => {
    form.setFieldsValue({
      type,
    });
    setPromotionInfo({ ...promotionInfo, type, value: '' });
    setSelectedProduct({});
  };

  const getDealData = async () => {
    try {
      const queryString = 'filter[status]=true&sort[name]=asc&filter[segmentType]=everyone';
      const deals = await getOffersList({ queryString }).unwrap();
      setDealsdata(deals.deals);
    } catch (error) {
      toast.error({ title: error.message });
    }
  };

  const getProductInfo = data => {
    let defaultCode = 0;
    if (data && sizes) {
      defaultCode = data.productCode;
      if (data.type !== '1') {
        const productVariants = sizes.filter(
          ({ classCode, item, state }) => state === 'A' && classCode === data.classCode && item === data.item,
        );
        if (productVariants.length > 0) {
          defaultCode = getDefaultSize(productVariants).productCode;
        }
      }
    }
    return { productCode: defaultCode };
  };

  const handleChangeProduct = v => {
    const product = products.filter(p => p.id == v)[0];
    setSelectedProduct(product);
  };

  return (
    <ModalDlg className="promotion_new_modal" visible={isOpenModal} width={430} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <h3>
          {isCreatePromotion ? (
            <FormattedMessage id="Create action screen" />
          ) : (
            <FormattedMessage id="Edit action screen" />
          )}
        </h3>
        <p className="description">
          <FormattedMessage id="All the information below is required" />
        </p>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="modal_content">
          <Form.Item>
            <p className="title">
              <FormattedMessage id="Action screen name" />
            </p>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'Please input your screen name!',
                  }),
                },
              ],
              initialValue: promotionInfo.name,
            })(<Input placeholder="Ingresar nombre" />)}
          </Form.Item>
          <Form.Item>
            <p className="title">
              <FormattedMessage id="Type of action" />
            </p>
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'Action type is required!',
                  }),
                },
              ],
              initialValue: promotionInfo.type || 'U',
            })(
              <Select
                placeholder="Action type"
                suffixIcon={<ArrowDropDown />}
                onChange={handleActionType}
                dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
              >
                {actionTypes.map(item => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          {promotionInfo.type === 'U' && (
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Destination" />
              </p>
              {getFieldDecorator('webUrl', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'Please input web url!' }),
                  },
                ],
                initialValue: promotionInfo.value,
              })(<Input placeholder="https://" />)}
            </Form.Item>
          )}
          {promotionInfo.type === 'P' && (
            <div className="product_picker_wrapper">
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Product" />
                </p>
                {getFieldDecorator('product', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'Product is required!',
                      }),
                    },
                  ],
                  initialValue:
                    promotionInfo.value &&
                    products.length !== 0 &&
                    !isNaN(promotionInfo.value, 10) &&
                    products.findIndex(p => p.id == promotionInfo.value) !== -1
                      ? parseInt(promotionInfo.value)
                      : '',
                })(
                  <Select
                    style={{ width: 280 }}
                    showSearch
                    placeholder="Seleccionar producto"
                    optionFilterProp="children"
                    onChange={v => handleChangeProduct(v)}
                    dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    filterOption={(input, option) =>
                      option.props.children.props.dangerouslySetInnerHTML.__html
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    suffixIcon={<ArrowDropDown />}
                  >
                    {!isLoadProduct ? (
                      products.map(item => (
                        <Option value={item.id} key={item.id}>
                          <p dangerouslySetInnerHTML={{ __html: item.name || '' }} style={{ marginBottom: '0' }} />
                        </Option>
                      ))
                    ) : (
                      <Option disabled value={1} key={1} style={{ height: '100px' }}>
                        <Loading visible={true} style={{ background: 'white' }} />
                      </Option>
                    )}
                  </Select>,
                )}
              </Form.Item>
              <div className="photo_area">
                {selectedProduct && selectedProduct.productCode !== undefined && (
                  <Img
                    src={
                      selectedProduct.productCode !== undefined
                        ? `${process.env.REACT_APP_S3}/${selectedProduct.channel === 'APP' ? 'customer' : 'ots'}/${
                            selectedProduct.productCode > 0
                              ? selectedProduct.productCode
                              : getProductInfo(selectedProduct).productCode
                          }.jpg`
                        : placeHolderImg
                    }
                  />
                )}
              </div>
            </div>
          )}
          {promotionInfo.type === 'O' && (
            <div className="product_picker_wrapper">
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Offers" />
                </p>
                {getFieldDecorator('offer', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'This field is required!',
                      }),
                    },
                  ],
                  initialValue: promotionInfo.value && promotionInfo.value.length > 0 ? promotionInfo.value : '',
                })(
                  <Select
                    style={{ width: '360px' }}
                    showSearch
                    placeholder="Offertas"
                    optionFilterProp="children"
                    dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    filterOption={(input, option) =>
                      option.props.children.props.dangerouslySetInnerHTML.__html
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    suffixIcon={<ArrowDropDown />}
                  >
                    {dealsData.length > 0 ? (
                      dealsData.map(item => (
                        <Option value={`${item.id} - ${item.name}`} key={item.id}>
                          <p dangerouslySetInnerHTML={{ __html: item.name || '' }} style={{ marginBottom: '0' }} />
                        </Option>
                      ))
                    ) : (
                      <Option disabled value={1} key={1} style={{ height: '100px' }}>
                        <Loading visible={true} style={{ background: 'white' }} />
                      </Option>
                    )}
                  </Select>,
                )}
              </Form.Item>
            </div>
          )}
          <div className="date_picker_wrapper">
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Start date" />
              </p>
              {getFieldDecorator('startAt', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'Start date is required!',
                    }),
                  },
                ],
                initialValue: promotionInfo.startAt ? dayjs(promotionInfo.startAt).tz('America/Guatemala') : '',
              })(<DatePicker />)}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Finish date" />
              </p>
              {getFieldDecorator('endAt', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'End date is required!' }),
                  },
                ],
                initialValue: promotionInfo.endAt ? dayjs(promotionInfo.endAt).tz('America/Guatemala') : '',
              })(<DatePicker />)}
            </Form.Item>
          </div>
          <div className="date_picker_wrapper">
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Start time" />
              </p>
              {getFieldDecorator('startTimeAt', {
                rules: [],
                initialValue: promotionInfo.startTimeAt ? dayjs(promotionInfo.startTimeAt, 'HH:mm') : null,
              })(<TimePicker format="HH:mm" placeholder="Seleccionar" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Finish time" />
              </p>
              {getFieldDecorator('endTimeAt', {
                rules: [],
                initialValue: promotionInfo?.endTimeAt ? dayjs(promotionInfo.endTimeAt, 'HH:mm') : null,
              })(<TimePicker format="HH:mm" placeholder="Seleccionar" />)}
            </Form.Item>
          </div>
          <div className="photo_picker_wrapper">
            <div className="photo_info">
              <p className="title">
                <FormattedMessage id="Banner photography" />
              </p>
              <p className="description">
                Archivo {dimentions.type} de {dimentions.width}px por {dimentions.height}px
              </p>
              <Button className="select_photo_btn" onClick={openChooseFile}>
                <FormattedMessage id="Select File" />
              </Button>
            </div>
            <input
              type="file"
              className="file_input"
              accept="image/*, .svg"
              ref={fileRef}
              onChange={handleFileChange}
            />
            <div className="photo_area">
              {(promotionInfo.picture || fileSrc) && (
                <Img src={fileSrc || getImageLink(promotionInfo.picture)} alt="promotion" onClick={openChooseFile} />
              )}
            </div>
          </div>
          {!promotionInfo.picture && isSubmit && (
            <p className="error_msg">{intl.formatMessage({ id: 'Banner photography is required!' })}</p>
          )}
          <div className="promotion_order_wrapper">
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Deployment order" />{' '}
                <span className="subtitle">
                  <FormattedMessage id="(Smaller number will have priority)" />
                </span>
              </p>
              {getFieldDecorator('order', {
                rules: [],
                initialValue: promotionInfo.order || '',
              })(
                <Select suffixIcon={<ArrowDropDown />} dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}>
                  {promotionOrders.map(v => (
                    <Option value={v} key={v}>
                      <p>{v}</p>
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
        </div>
        <div className="modal_footer">
          <div className="aditional_detail">
            <p>
              <FormattedMessage id="Active" />
            </p>
            <Form.Item>
              {getFieldDecorator('state', {
                rules: [],
                initialValue: promotionInfo.state === 'A',
              })(
                <Switch
                  checked={promotionInfo.state === 'A'}
                  onChange={checked =>
                    setPromotionInfo({
                      ...promotionInfo,
                      state: checked ? 'A' : 'I',
                    })
                  }
                />,
              )}
            </Form.Item>
          </div>
          <div className="btn_wrapper">
            <Button className="close_btn" htmlType="submit">
              {isCreatePromotion ? <FormattedMessage id="Create screen" /> : <FormattedMessage id="Edit Screen" />}
              <ArrowForward />
            </Button>
          </div>
        </div>
      </Form>
    </ModalDlg>
  );
}

PromotionNewModal.defaultProps = {
  promotion: {},
  isCreatePromotion: false,
  form: {},
  isOpenModal: false,
  products: [],
  sizes: [],
};

export default compose(injectIntl, Form.create({ name: 'promotion_form' }), withRouter)(PromotionNewModal);
